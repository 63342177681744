import { gql } from 'graphql.macro';

export const GET_BASE_CLIENT_DATA = gql`
  query {
    app @client {
      showBeforeAndAfterMosaic
      baseParams {
        groupType
        territoryType
        territorySubtype
        territoryValueType
        territory
        territories {
          id
          label
          territoryTypeId
          territoryTypeLabel
          territoryBBox
        }
        crossing
        yearRange
      }
    }
  }
`;


export const GET_BASE_DATA = gql`
  query {
    territoryCategoriesList {
      id
      key
      parentId
      i18nStrings {
        language
        stringValue
      }
    }

    coverageTransitionPeriodOptionsList {
      id
      fromYear
      toYear
      groupBy
      orderAmongSiblings
    }
  }
`;
export const GET_TERRITORIES_LIST = gql`
  query TerritoriesList($categoryId: Int!) {
    territoriesList(categoryId: $categoryId) {
      id
      territoryGeometry {
        boundingBox
      }
      i18nStrings {
        language
        stringValue
      }
    }
  }
`;