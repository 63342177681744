import React from 'react';
import numbro from 'numbro';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';

import MinimalBarData from '../MinimalBarData';

import styles from '../../StatisticsBox.module.scss';

const formatNumber = (value) => {
  if (!value) { return '-'; }

  return numbro(value).format({
    thousandSeparated: true,
    mantissa: 0
  });
};

export default function BaseValuesChart({
  title = 'Área queimada no período',
  total,
  totalTitle = 'Total',
  maxValue,
  maxYear,
  averageValue,
  averagePercentage,
  minValue,
  minYear,
  minPercentage,
}) {
  const formatMessage = useFormatMessage();

  return (
    <div className={ styles.section }>
      <h2 className={ styles.secondaryTitle }>{ title }</h2>
      <ul className={ styles.infoList }>
        <li className={ styles.infoListItem }>
          <span className={ styles.infoListItemValue }>
            <b>{ formatNumber(total) }</b>
            <small>ha</small>
          </span>
          <span className={ styles.infoListItemLabel }>{ totalTitle }</span>
        </li>
      </ul>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 }>
          <MinimalBarData
            label={ formatMessage('mapbiomas.statistics.summary.max.title') }
            tooltip={ formatMessage('mapbiomas.statistics.summary.max.tooltip') }
            value={ maxValue }
            year={ maxYear }
            percentage={ maxValue ? 100 : 0 }
          />
        </Grid>
        <Grid item xs={ 12 }>
          <MinimalBarData
            label={ formatMessage('mapbiomas.statistics.summary.average.title') }
            tooltip={ formatMessage('mapbiomas.statistics.summary.average.tooltip') }
            value={ averageValue }
            percentage={ !_.isNaN(averagePercentage) ? averagePercentage : 0 }
          />
        </Grid>
        <Grid item xs={ 12 }>
          <MinimalBarData
            label={ formatMessage('mapbiomas.statistics.summary.min.title') }
            tooltip={ formatMessage('mapbiomas.statistics.summary.min.tooltip') }
            value={ minValue }
            year={ minYear }
            percentage={ !_.isNaN(minPercentage) ? minPercentage : 0 }
          />
        </Grid>
      </Grid>
    </div>
  );
}
