import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import classnames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';

import TabList from '../../../../components/TabList';

import { GET_BASE_DATA } from './query';

import styles from './ClassLegendDialog.module.scss';

export default function ClassLegendDialog({
  headerIsVisible,
  isOpen,
  classId,
  onClose = () => {}
}) {
  const locale = localStorage.getItem('locale') || 'es';
  const [currentTab, setCurrentTab] = useState(1);
  const [tabItems, setTabItems] = useState([]);
  const { data: baseData, loading } = useQuery(GET_BASE_DATA, {
    variables: {
      classId
    },
    skip: !classId
  });

  const classesTooltipsList = _.get(baseData, 'classTreeLevels[0].classTreeNode.classe.classesTooltips');

  useEffect(() => {
    if (classesTooltipsList) {
      const parsedTabItems = _.map(classesTooltipsList, (item) => {
        const labelStringList = _.get(item, 'titleString');
        const labelSelectedString = _.find(labelStringList, { language: 'es' });

        return {
          id: item.id,
          label: _.get(labelSelectedString, 'stringValue')
        };
      });

      setTabItems(parsedTabItems);
      setCurrentTab(_.get(_.first(parsedTabItems), 'id'));
    }
  }, [classesTooltipsList]);

  const handleTabChange = (newValue) => {
    setCurrentTab(newValue);
  };

  const renderContent = () => {
    const selectedData = _.find(classesTooltipsList, { id: currentTab });

    if (!selectedData) { return null; }

    const bodyStringList = _.get(selectedData, 'bodyString');
    const bodySelectedString = _.find(bodyStringList, { language: locale });

    const imageList = _.get(selectedData, 'classesTooltipImages');

    return (
      <Fragment>
        <div
          className={ styles.contentDescription }
          dangerouslySetInnerHTML={{
            __html: _.get(bodySelectedString, 'stringValue')
          }}
        />
        { !_.isEmpty(imageList) &&
          <ul className={ styles.imageList }>
            { _.map(imageList, (image) => {
              const imageStringList = _.get(image, 'i18nStrings');
              const imageSelectedString = _.find(imageStringList, { language: locale });

              return (
                <li key={ `image-item-${ currentTab }-${ image.id }` }>
                  <img src={ image.url } />
                  <span>{ _.get(imageSelectedString, 'stringValue') }</span>
                </li>  
              );
            }) }
          </ul>
        }
      </Fragment>
    );
  };

  const titleStringList = _.get(baseData, 'classTreeLevels[0].i18nStrings');
  const titleSelectedString = _.find(titleStringList, { language: locale });

  return (
    <Fade in={ isOpen && !loading }>
      <div className={ classnames(styles.wrapper, {
        [styles.wrapperWithHeader]: headerIsVisible
      }) }>
        <header className={ styles.header }>
          <h2 className={ styles.title }>{ _.get(titleSelectedString, 'stringValue') }</h2>
          <IconButton className={ styles.closeButton } onClick={ onClose }>
            <CloseIcon />
          </IconButton>
        </header>
        <div className={ styles.content }>
          <TabList
            itemSizes={{ xs: 4 }}
            items={ tabItems }
            value={ currentTab }
            onChange={ handleTabChange }
          />
          { renderContent() }
        </div>
      </div>
    </Fade>
  );
}
