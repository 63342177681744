import { gql } from 'graphql.macro';

export const GET_BASE_CLIENT_DATA = gql`
  query {
    app @client {
      activeModule
      baseParams {
        groupType
        territoryType
        territorySubtype
        territoryValueType
        territory
        territories {
          id
          label
          territoryTypeId
          territoryTypeLabel
          territoryBBox
        }
        crossing
        buffer
        yearRange
      }
    }
  }
`;

export const GET_BASE_DATA = gql`
  query {
    geometryBuffersList {
      id
      bufferSizeInMeters
			i18nStrings {
				language
				stringValue
			}
    }

   territoryCategoriesList {
     id
     key
     parentId
		 territoryLayerStyle
     i18nStrings {
			 language
			 stringValue
     }
   }
  }
`

export const GET_TERRITORIES_LIST = gql`
  query TerritoriesList($categoryId: Int!) {
    territoriesList(categoryId: $categoryId) {
      id
      territoryGeometry {
        boundingBox
      }
      i18nStrings {
        language
        stringValue
      }
    }
  }
`;
