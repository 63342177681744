import { gql } from 'graphql.macro';

export const GET_BASE_DATA = gql`
  {
    app @client {
      activeModule
      activeModuleContent
      headerIsVisible
      baseParams {
        classesViewMode
        activeClassTreeNodeIds
        activeObjectTreeNodeIds
        activeClassesLevelsListItems
        buffer
      }
    }
  }
`

export const GET_MODULE_DATA = gql`
  query GetModulesData {
    modulesList {
      id
      key
      enabled
      isInBeta
      primaryColor
      secondaryColor
      iconUrl
      displayOrder
			i18nStrings {
				language
				stringValue
      }
			submodules {
        id
        displayOrder
        key
				geospatialObjectCategoryTree {
          id
          key
          geospatialObjectCategoryTreeLevels {
            id
            color
            positionInTree
            level
            parentId
            childrenIds
            i18nStrings {
							stringValue
							language
            }
						geometryCategory {
							i18nStrings {
								stringValue
								language
							}	
						}
          }
          i18nStrings {
						stringValue
						language
          }
        }
        i18nStrings {
					language
					stringValue
        }
      }
    }
  }
`
