import React, { useState } from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import Collapse from '@material-ui/core/Collapse'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { updateStateCache } from '../../../../../../utils/apollo'
import { onCurrentLocaleCustom } from '../../../../../../utils/locale'

import { GET_COMPONENT_INITIALIZATION_DATA } from './query'

import styles from './GeospatialObjects.module.scss'

const useStyles = makeStyles((theme) => ({
  link: {
    color: `#16a085 !important`,
  },
}))

const MAP_POSITION_IN_TREE_TO_DOWNLOAD_URL = {
  '1.1.1': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/transport/mapbiomas_infra_aerodromos.zip',
  '1.2.1': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/transport/mapbiomas_infra_eclusa.zip',
  '1.2.2': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/transport/mapbiomas_infra_estacao_transbordo_cargas.zip',
  '1.2.3': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/transport/mapbiomas_infra_hidrovia.zip',
  '1.2.4': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/transport/mapbiomas_infra_instalacao_portuaria_turismo.zip',
  '1.2.5': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/transport/mapbiomas_infra_instalacao_portuaria_sob_registro.zip',
  '1.2.6': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/transport/mapbiomas_infra_porto.zip',
  '1.2.7': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/transport/mapbiomas_infra_terminal_uso_privado.zip',
  '1.3.1': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/transport/mapbiomas_infra_aquaduto.zip',
  '1.3.2': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/transport/mapbiomas_infra_gasoduto.zip',
  '1.3.3': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/transport/mapbiomas_infra_mineroduto.zip',
  '1.3.4': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/transport/mapbiomas_infra_oleoduto.zip',
  '1.3.5': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/transport/mapbiomas_infra_poliduto.zip',
  '1.3.6': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/transport/mapbiomas_infra_duto_nao_identificado.zip',
  '1.4.1': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/transport/mapbiomas_infra_ferrovias.zip',
  '1.5.1': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/transport/mapbiomas_infra_trechos_rodoviarios.zip',
  '1.5.2': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/transport/mapbiomas_infra_rodovias_federais.zip',
  '2.1.1': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_refinarias_petroleo.zip',
  '2.1.2': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_terminal_armazenamento_petroleo.zip',
  '2.1.3': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_estacao_compressao.zip',
  '2.1.4': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_ponto_entrega_gas.zip',
  '2.1.5': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_processamento_gas_natural.zip',
  '2.1.6': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_usina_biodiesel.zip',
  '2.1.7': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_usina_biogas.zip',
  '2.1.8': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_usina_etanol.zip',
  '2.1.9': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_base_glp.zip',
  '2.1.10': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_base_combustivel_liquido.zip',
  '2.2.1': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_subestacao.zip',
  '2.2.2': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_linhas_transmissao.zip',
  '2.2.3.1': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_usinas_eolicas.zip',
  '2.2.3.2': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_usinas_fotovoltaicas.zip',
  '2.2.3.3': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_usinas_termeletricas_biomassa.zip',
  '2.2.3.4': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_usinas_hidreletricas.zip',
  '2.2.3.5': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_pequena_central_hidreletrica.zip',
  '2.2.3.6': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_centrais_geradoras_hidreletricas.zip',
  '2.2.4.7': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_termeletricas_combustivel_fossil.zip',
  '2.2.4.8': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/energy/mapbiomas_infra_termonuclear.zip',
  '3.1': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/mining/mapbiomas_infra_mina_energeticos.zip',
  '3.2': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/mining/mapbiomas_infra_mina_metalicos.zip',
  '3.3': 'https://storage.googleapis.com/mapbiomas-public/brasil/collection-6/lclu/downloads/infrastructure/mining/mapbiomas_infra_mina_outros_produtos.zip'
};

export default function GeospatialObjects({
  objectTree = []
}) {
  const classes = useStyles()
  const apolloClient = useApolloClient()
  const { data, loading } = useQuery(GET_COMPONENT_INITIALIZATION_DATA)
  if (loading || !data) {
    return null
  }

  const appState = data.app
  const updateState = _.partial(updateStateCache, apolloClient)

  const [expandedNodeIds, setExpandedNodeIds] = useState([])
  const nodes = _.get(objectTree, 'geospatialObjectCategoryTreeLevels')

  const toggleTreeNode = (node) => {
    if (_.includes(expandedNodeIds, node.id)) {
      setExpandedNodeIds(_.without(expandedNodeIds, node.id))
    } else {
      setExpandedNodeIds([ ...expandedNodeIds, node.id ])
    }
  }

  const handleObjectTreeNodeToggle = (objectTreeNode) => {
    const nodeId = _.get(objectTreeNode, 'id')

    if (_.includes(appState.baseParams.activeObjectTreeNodeIds, nodeId)) {
      updateState('app.baseParams', [
        'activeObjectTreeNodeIds',
        _.without(appState.baseParams.activeObjectTreeNodeIds, nodeId)
      ])
    } else {
      updateState('app.baseParams', [
        'activeObjectTreeNodeIds',
        [ ...appState.baseParams.activeObjectTreeNodeIds, nodeId ]
      ])
    }
  }

  const renderExpandIcon = (node) => {
    const expanded = _.includes(expandedNodeIds, node.id)
    const icon = expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />

    if (!_.isEmpty(_.get(node, 'childrenIds'))) {
      return (
        <IconButton
          className={ styles.classListItemContentControl }
          onClick={ _.partial(toggleTreeNode, node) }
        >
          { icon }
        </IconButton>
      )
    }
  }

  const renderCheckbox = (node) => {
    const toggled = _.includes(appState.baseParams.activeObjectTreeNodeIds, node.id)

    return (
      <IconButton
        className={ styles.classListItemCheckControl }
        style={{ color: node.color }}
        onClick={ _.partial(handleObjectTreeNodeToggle, node) }
      >
        { toggled ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> }
      </IconButton>
    )
  }

  const renderClassTreeBranch = ({ parentId = null }) => {
    const branchNodes = _.filter(nodes, { parentId })

    return (
      <ul
        className={ classnames(styles.classNode, {
          [styles.classChildNode]: !_.isNil(parentId)
        }) }
      >
        { _.map(_.sortBy(branchNodes, _.flow(_.property('positionInTree'), _.last)), (node) => {
          const prettyIndexOfNodeInTree = _.join(node.positionInTree, '.');
          const downloadUrl = MAP_POSITION_IN_TREE_TO_DOWNLOAD_URL[prettyIndexOfNodeInTree];

          return (
            <li
              key={ `class-item-level-${ node.id }` }
              className={ styles.classListItemBase }
            >
              <div className={ styles.classListItemHeader }>
                { _.isEmpty(node.childrenIds) && renderCheckbox(node) }
                <span
                  style={{ fontSize: 12 }}
                  className={ styles.classListItemTitle }
                >
                  <span>{ prettyIndexOfNodeInTree }. { onCurrentLocaleCustom(node.i18nStrings) }</span>
                  { downloadUrl &&
                    <IconButton
                      className={ styles.actionBoxControlButton }
                      href={ downloadUrl }
                      target="_blank"
                    >
                      <CloudDownloadIcon />
                    </IconButton>
                  }
                </span>
                { renderExpandIcon(node) }
              </div>
              <Collapse in={ _.includes(expandedNodeIds, node.id) }>
                <div>
                  { renderClassTreeBranch({ parentId: node.id }) }
                </div>
              </Collapse>
            </li>
          )
        }) }
      </ul>
    )
  }

  return (
    <div className={ styles.wrapper }>
      <h2 className={ styles.title }>
        Categorias
      </h2>
      <p className={ styles.descriptionText }><FormattedMessage id="mapbiomas.header.infrastructure.description.click" /> <a href="https://mapbiomas-br-site.s3.amazonaws.com/Gloss%C3%A1rio_-_Camadas_de_Infraestrutura_2020_-_PORT___ENG.xlsx" target="_blank" className={ classes.link }><FormattedMessage id="mapbiomas.header.infrastructure.description.here" /></a> <FormattedMessage id="mapbiomas.header.infrastructure.description.content" /></p>
      { renderClassTreeBranch({ parentId: null }) }
    </div>
  )
}
