import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';

import styles from './ClassLegendList.module.scss';

export default function ClassLegendList({
  disabled,
  activeClassesLevelsListItems,
  parsedClassesLevelsList,
  onItemsChange = () => {},
  onShowInfo,
  onlyShowControlOnLeafs = false,
  hideIndex = false,
}) {
  const formatMessage = useFormatMessage();
  const [expandedItems, setExpandedItems] = useState([]);

  useEffect(() => {
    if (disabled) {
      setExpandedItems([]);
    }
  }, [disabled]);

  const itemIsActive = (id) => {
    return _.includes(activeClassesLevelsListItems, id);
  };

  const toggleActiveItem = (id) => {
    if (itemIsActive(id)) {
      let itemsClone = _.clone(activeClassesLevelsListItems);
      itemsClone = _.filter(itemsClone, (itemId) => itemId !== id);

      onItemsChange(itemsClone);
    } else {
      onItemsChange([
        ...activeClassesLevelsListItems,
        id
      ]);
    }
  };

  const toggleExpandedItem = (id) => {
    if (_.includes(expandedItems, id)) {
      let itemsClone = _.clone(expandedItems);
      itemsClone = _.filter(itemsClone, (itemId) => itemId !== id);

      setExpandedItems(itemsClone);
    } else {
      setExpandedItems([
        ...expandedItems,
        id
      ]);
    }
  };

  const renderList = (level, parentId, baseIndex) => {
    const filteredItems = _.filter(parsedClassesLevelsList, (item) => {
      if (parentId) {
        return item.level === level && item.parentId === parentId;
      } else {
        return item.level === level;
      }
    });

    return (
      <ul className={ classnames(styles.classList, {
        [styles.classListLevel2]: !onlyShowControlOnLeafs && level === 2,
        [styles.classListLevel3]: !onlyShowControlOnLeafs && level === 3,
        [styles.classListLevel4]: !onlyShowControlOnLeafs && level === 4,
        [styles.classListControlOnLeaf]: onlyShowControlOnLeafs && level !== 1,
        [styles.classListControlDisabled]: disabled,
      }) }>
        { _.map(_.sortBy(filteredItems, 'position'), (item, index) => {
          const isExpanded = _.includes(expandedItems, item.id);
          const hasChildItems = !!_.find(parsedClassesLevelsList, { level: level + 1, parentId: item.id });
          const indexLabel = baseIndex ? `${ baseIndex }.${ index + 1 }` : `${ index + 1 }`;
          const showItemControl = onlyShowControlOnLeafs ? !hasChildItems : true;
          const label = !hideIndex ? `${ indexLabel }. ${ item.label }` : item.label;

          return (
            <li
              key={ `class-item-level-${ item.id }` }
              className={ styles.classListItemBase }
            >
              <div className={ styles.classListItemHeader }>
                { showItemControl &&
                  <IconButton
                    disabled={ disabled }
                    className={ styles.classListItemCheckControl }
                    style={{ color: item.color }}
                    onClick={ toggleActiveItem.bind(this, item.id) }
                  >
                    { itemIsActive(item.id) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> }
                  </IconButton>
                }
                <span className={ styles.classListItemTitle }>{ label }</span>
                { hasChildItems &&
                  <IconButton
                    disabled={ disabled }
                    className={ styles.classListItemContentControl }
                    onClick={ toggleExpandedItem.bind(this, item.id) }
                  >
                    { isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                  </IconButton>
                }
                { item.tooltipsList && onShowInfo &&
                  <Tooltip title={ formatMessage('mapbiomas.header.classes_control.tooltip.info') }>
                    <IconButton
                      className={ styles.classListItemInfoControl }
                      onClick={ onShowInfo.bind(this, item.id) }
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                }
              </div>
              <Collapse in={ isExpanded }>
                <div>
                  { renderList(level + 1, item.id, indexLabel) }
                </div>
              </Collapse>
            </li>
          );
        }) }
      </ul>
    );
  };

  return (
    <div>
      { renderList(1) }
    </div>
  );
}
