import React from 'react'
import { FormattedMessage } from 'react-intl'

import styles from './PageFooter.module.scss'
import logo from './logo-ecostage.svg';

export default function PageFooter() {
  return(
    <footer className={ styles.footer }>
      <small className={ styles.copyText }><FormattedMessage id="footer.copyright" /> · Mapbiomas · 2022 · v2.0.8</small>
      <div className={ styles.wrapper }>
        <small className={ styles.versionText }>{ process.env.GIT_TAG } · </small>
        <a href="http://www.ecostage.com.br/" target="_blank">
          <img className={ styles.ecostageImage } src={ logo } alt="Logo EcoStage" />
        </a>
      </div>
    </footer>
  )
}
