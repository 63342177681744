import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { CSVLink } from 'react-csv';

import { FormattedMessage } from 'react-intl';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';

import StickyTable from '../DashboardDialog/components/StickyTable';

import styles from './StatisticsDataTableBox.module.scss';

function DataTableBox({
  title,
  columns,
  rows,
}) {
  const renderDownloadTableLink = () => {
    if (_.isEmpty(columns) || _.isEmpty(rows)) {
      return null;
    }

    let headers = [
      { key: 'class', label: 'Classe' },
    ];

    _.each(columns, (column) => {
      headers.push({ key: _.toString(column), label: _.toString(column) });
    });

    const parsedData = _(rows)
      .map((serie) => {
        let rowData = { class: _.get(serie, 'name') };

        _.each(_.get(serie, 'data'), (value, index) => {
          rowData[_.toString([columns[index]])] = value;
        });

        return rowData;
      })
      .flatten()
      .value();

    return (
      <CSVLink
        data={ parsedData }
        headers={ headers }
        className={ styles.csvDownloadLink }
        filename="MapBiomas - Tabela de Dados.csv"
      >
        <CloudDownloadIcon />
        <span>Download</span>
      </CSVLink>
    );
  };

  return (
    <div className={ styles.dataTableWrapper }>
      <div className={ styles.tableHeader }>
        <h3 className={ styles.tableTitle }>
          <b>{ title }</b>
        </h3>
        { renderDownloadTableLink() }
      </div>
      <StickyTable
        columns={ columns }
        rows={ rows }
      />
    </div>
  );
}

export default function StatisticsDataTableBox({
  headerIsVisible,
  data,
  onClose = () => {}
}) {
  return (
    <Fade in={ !!data }>
      <div className={ classnames(styles.wrapper, {
        [styles.wrapperWithHeader]: headerIsVisible,
      }) }>
        <header className={ styles.header }>
          <h2 className={ styles.title }>
            <span><FormattedMessage id="mapbiomas.dashboard.generic.data_table.title" /></span>
          </h2>
          <IconButton
            className={ styles.closeButton }
            onClick={ onClose }
          >
            <CloseIcon />
          </IconButton>
        </header>
        <div className={ styles.content }>
          { _.map(data, (item, index) => {
            return (
              <DataTableBox
                key={ `data-table-${ _.kebabCase(_.get(item, 'title')) }-${ index }` }
                title={ _.get(item, 'title') }
                columns={ _.get(item, 'columns') }
                rows={ _.get(item, 'rows') }
              />
            );
          }) }
        </div>
      </div>
    </Fade>
  );
}
