import React from 'react';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import numbro from 'numbro';

import { useIntl } from 'react-intl';

function tooltipFormatter(params) {
  const colorSpan = (color) => `<br /><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${ color }"></span>`;
  let str = `<span>${ params[0].axisValue }</span>`;

  params.forEach((item) => {
    const value = _.isObject(item.data) ? item.data.value : item.data;

    if (value) {
      const valueLabel = numbro(value).format({
        thousandSeparated: true,
        mantissa: 0
      });
      const paramValue = `<span>${ colorSpan(item.color) } ${ item.seriesName }: ${ valueLabel }</span>`;

      str += paramValue;
    }
  });

  return str;
}

const yAxisLabelFormatter = (value) => {
  return numbro(value / 1000000).format({
    thousandSeparated: true,
    mantissa: 0
  });
};

export default function BarChart({
  isPercentage,
  categories = [],
  series = [],
  vertical = true,
  customYAxisLabelFormatter = yAxisLabelFormatter,
  yAxisName,
}) {
  const intl = useIntl()

  let parsedYAxisName = yAxisName;

  if (_.isUndefined(yAxisName)) {
    parsedYAxisName = intl.formatMessage({id: 'mapbiomas.statistics.hectares'});
  }

  let xAxis = {
    type: "category",
    axisPointer: {
      type: "shadow"
    },
    axisLabel: {
      fontSize: 11,
      color: "#767676"
    },
    axisLine: {
      color: "#DDD"
    },
    data: categories
  };
  let yAxis = {
    name: parsedYAxisName || null,
    nameLocation: 'middle',
    nameRotate: 90,
    nameGap: 25,
    nameTextStyle: {
      fontSize: 11,
      color: "#767676"
    },
    max: isPercentage ? 100 : undefined,
    axisTick: {
      show: false
    },
    axisLine: {
      color: "#DDD"
    },
    splitLine: {
      lineStyle: {
        color: "rgba(0, 0, 0, 0.05)"
      }
    },
    axisLabel: {
      formatter: (value) => {
        if (customYAxisLabelFormatter) {
          return customYAxisLabelFormatter(value);
        } else {
          const valueLabel = numbro(value).format({
            thousandSeparated: true,
            mantissa: 0
          });

          return valueLabel;
        }
      },
      fontSize: 11,
      color: "#767676"
    }
  };

  if (!vertical) {
    let tempValue = xAxis;
    xAxis = yAxis;
    yAxis = tempValue;
  }

  return (
    <ReactEcharts
      style={ { height: 200 } }
      option={ {
        xAxis,
        yAxis,
        tooltip: {
          appendToBody: true,
          // position: [10, 10],
          // position: 'right',
          // position: function (point, params, dom, rect, size) {
          //   console.log('point', point);
          //   // fixed at top
          //   return [0, 0];
          //   // return {
          //   //   right: point[0],
          //   //   top: -10
          //   // };
          // },
          textStyle: {
            align: "left",
            color: "#444",
            fontSize: 13
          },
          backgroundColor: "#FFFFFF",
          borderColor: "rgba(0, 0, 0, 0.1)",
          borderWidth: 1,
          extraCssText: "box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05);",
          trigger: "axis",
          formatter: tooltipFormatter,
        },
        grid: {
          top: "8%",
          right: "10px",
          bottom: "8%",
          left: parsedYAxisName ? '20px' : '10px',
          containLabel: true
        },
        series: _.map(series, (serie) => {
          return {
            ...serie,
            type: 'bar',
            smooth: true,
          };
        }),
        // toolbox: {
        //   feature: {
        //     saveAsImage: {
        //       name: 'MapBiomas',
        //       title: 'Download',
        //     }
        //   }
        // },
      } }
      notMerge={ true }
    />
  );
}
