import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

const cache = new InMemoryCache();

const link = new HttpLink({
  uri: '/api/graphql',
  // TODO: Use old production graphql endpoint
  // uri: 'https://plataforma.mapbiomas.collection6.mapbiomas.org/api/graphql',
});

const client = new ApolloClient({
  cache,
  link,
});

export default client;
