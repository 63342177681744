import React from 'react';
import _ from 'lodash';
import numbro from 'numbro';

import { ResponsiveSankey } from '@nivo/sankey';

function tooltipFormatter(value) {
  if (!value) { return ''; }

  const valueFormatted = numbro(value).format({
    thousandSeparated: true,
    mantissa: 0
  });

  return `${ valueFormatted } ha`
}

const MyResponsiveSankey = ({ data }) => (
  <ResponsiveSankey
    data={data}
    margin={{ top: 0, right: 180, bottom: 10, left: 180 }}
    align="justify"
    colors={ (node) => {
      return node.color;
    } }
    nodeOpacity={1}
    nodeInnerPadding={3}
    nodeSpacing={24}
    nodeBorderWidth={0}
    nodeBorderColor={{ from: 'color', modifiers: [ [ 'darker', 0.8 ] ] }}
    linkOpacity={0.65}
    linkHoverOpacity={0.65}
    linkHoverOthersOpacity={0.1}
    enableLinkGradient={true}
    labelPosition="outside"
    labelPadding={16}
    labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1 ] ] }}
    animate={true}
    motionStiffness={140}
    motionDamping={13}
    label={ (node) => {
      return node.label;
    } }
    tooltipFormat={ tooltipFormatter }
  />
)

export default function SankeyChart({
  nodes,
  links,
}) {
  if (!nodes || !links) { return null; }

  return (
    <MyResponsiveSankey
      data={ { nodes, links } }
    />
  );
}
