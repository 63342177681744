import { gql } from 'graphql.macro';

export const GET_CLASS_TREE_DATA = gql`
  query GetClassTreeData(
    $classTreeKey: String!,
  ) {
    classTreeByKey(key: $classTreeKey) {
      id
      key
     	i18nStrings {
				language
				stringValue
      }
			mvClassTreeLevelsUi {
        id
        color
        classId
        childrenIds
        positionInTree
        level
        parentId
        defaultName
        classTreeId
				i18nStrings {
					language
					stringValue
				}
      }
    }
  }
`;

export const GET_BASE_DATA = gql`
  query BurnedAreaEvolutionMonthCalcs(
    $classTreeNodeIds: [Int!],
    $year: [Int!]!,
    $territoriesIds: [Int!],
  ) {
    burnedAreaEvolutionMonthCalcs(
      classTreeNodeIds: $classTreeNodeIds,
      year: $year,
      territoriesIds: $territoriesIds,
    ) {
      percentage,
      average,
      maximun,
      minimun,
      totalburnedarea,
    }

    burnedAreaEvolutionEachMonth(
      classTreeNodeIds: $classTreeNodeIds,
      year: $year,
      territoriesIds: $territoriesIds,
    ){
      average,
      maximun,
      minimun,
      totalburnedarea,
      classTreeNodeId
    }

    burnedAreaEvolutionYears(
      classTreeNodeIds: $classTreeNodeIds,
      years: [2000, 2023],
      territoriesIds: $territoriesIds,
    ){
      acumulated {
        classTreeNodeId
        total
      }
      territoriesid
      year
    }
  }
`;
