export default [
  {
    id: 1,
    labelKey: 'dark',
    url: 'http://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'ArcGis Online' },
    ],
  },
  {
    id: 2,
    labelKey: 'white',
    url: '/white-background.jpg',
    layerParams: {
      noWrap: true,
      attribution: 'Creative Commons image found <a href="http://mapbiomas.org">white canvas</a>'
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Mapbiomas.org' },
    ],
  },
  {
    id: 3,
    labelKey: 'satellite',
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Carto' },
    ],
  },
  {
    id: 4,
    labelKey: 'open_street',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'OpenStreetMap' },
    ],
  },
  {
    id: 5,
    labelKey: 'google_street',
    url: 'http://mt0.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Google' },
    ],
  },
  {
    id: 6,
    labelKey: 'relief',
    props: {
      tileType: 'wms',
      format: 'image/png',
      layers: '3d_context',
      transparent: true,
    url: `${process.env.REACT_APP_WMS_BASE_URL}/wms/3d_context.map`,
    },
    // url: 'https://earthengine.googleapis.com/v1alpha/projects/earthengine-legacy/maps/92aec3704e31404c601426f06344e54c-b132417f385010dbd2b516d189a02e7b/tiles/{z}/{x}/{y}',
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'ArcGis Online' },
    ],
  },
  {
    id: 7,
    labelKey: 'mosaic',
    props: {
      tileType: 'wms',
      format: 'image/png',
      layers: 'rgb',
      transparent: true,
      url: `${process.env.REACT_APP_WMS_BASE_URL}/wms/rgb.map`,
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Mapbiomas' },
    ],
  },
  {
    id: 8,
    labelKey: 'coverage',
    props: {
      tileType: 'wms',
      format: 'image/png',
      layers: 'coverage',
      transparent: true,
      url: `${process.env.REACT_APP_WMS_BASE_URL}/wms/coverage.map`,
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Mapbiomas' },
    ],
  },
  {
    id: 9,
    labelKey: 'planet',
    url: 'https://tileserver-mapbiomas.sccon.com.br/mapbiomas/tiles/1.0.0/planet_mosaic_2021_07/planet/{z}/{x}/{y}.png',
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Planet Labs' },
    ],
  },
];
