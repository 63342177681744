import React, { useEffect, useState, Fragment } from 'react';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import qs from 'qs';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';

import ClassLegendDialog from './components/ClassLegendDialog';
import CoordinatesBox from './components/CoordinatesBox';
import DashboardDialog from './components/DashboardDialog';
import MapCanvas from './components/MapCanvas';
import MapboxCanvas from './components/MapboxCanvas';
import MapboxControl from './components/MapboxControl';
import MapControls from './components/MapControls';
import MapPointDialog from './components/MapPointDialog';
import YearTimeline from './components/YearTimeline';
import YearTimelineMultiple from './components/YearTimelineMultiple';
import ShareControl from './components/ShareControl';
import StatisticsBox from './components/StatisticsBox';
import InfrastructureStatisticsBox from './components/InfrastructureStatisticsBox';
import StatisticsDataTableBox from './components/StatisticsDataTableBox';
import TutorialTour from '../../../../components/tutorial/TutorialTour';

import {
  GET_BASE_DATA,
  GET_TERRITORIES_LIST,
  GET_CLASS_TREE_DATA,
} from './query';

import useFormatMessage from '../../../../hooks/useFormatMessage';
import usePrevious from '../../../../hooks/usePrevious';

import parseQueryParamsToState from '../../../../helpers/parseQueryParamsToState';

import toast from '../../../../utils/toast';

import styles from './Map.module.scss';

export default function Map({
  activeBaseMap,
  activeLayers,
  layersOpacity,
  location,
}) {
  const client = useApolloClient();
  const formatMessage = useFormatMessage();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [activeNoteKey, setActiveNoteKey] = useState(null);
  const [readNoteKeys, setReadNoteKeys] = useState([]);
  const [coverageTutorial, setCoverageTutorial] = useState(true);
  const [statisticsData, setStatisticsData] = useState(null);
  const { data: baseData, loading: loadingBaseData } = useQuery(GET_BASE_DATA);
  const { data: defaultClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: 'default'
    }
  });
  const defaultClassTreeLevelsList = _.get(defaultClassTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
  const [
    loadCountryTerritoryData,
    {
      data: countryTerritoryListData,
    }
  ] = useLazyQuery(GET_TERRITORIES_LIST);
  const prevLoadingBaseData = usePrevious(loadingBaseData);
  const activeModule = _.get(baseData, 'app.activeModule');
  const activeModuleContent = _.get(baseData, 'app.activeModuleContent');
  const prevActiveModuleContent = usePrevious(activeModuleContent);
  const activeClassTreeOptionValue = _.get(baseData, 'app.baseParams.activeClassTreeOptionValue');
  const activeYear = _.get(baseData, 'app.activeYear');
  const baseParams = _.get(baseData, 'app.baseParams');
  const mapboxMode = _.get(baseData, 'app.mapboxMode');
  const territoryCategoriesList = _.get(baseData, 'territoryCategoriesList');

  const headerIsVisible = _.get(baseData, 'app.headerIsVisible');
  const mapFlyTo = _.get(baseData, 'app.mapFlyTo');
  const mapPointInfo = _.get(baseData, 'app.mapPointInfo');
  const showClassInfo = _.get(baseData, 'app.showClassInfo');
  const showDashboardInfo = _.get(baseData, 'app.showDashboardInfo');
  const showBeforeAndAfterMosaic = _.get(baseData, 'app.showBeforeAndAfterMosaic');
  const timelineLimitsRange = _.get(baseData, 'app.timelineLimitsRange');
  const mapPosition = _.get(baseData, 'app.mapPosition');

  const [lat, lng, zoom] = _.split(mapPosition, ',');
  let mapConfigOptions = {};

  if (mapPosition) {
    mapConfigOptions = {
      lat: Number(lat),
      lng: Number(lng),
      zoom: Number(zoom),
    };
  }

  let urlQueryParams = {};

  if (location && location.search) {
    const queryString = location.search.replace('?', '');
    const queryObject = qs.parse(queryString, { comma: true });

    if (queryObject) {
      urlQueryParams = parseQueryParamsToState(queryObject);
    }
  }

  const isOnInitialRenderingWithQueryParams = loadingBaseData ||
    (prevLoadingBaseData && !loadingBaseData && !_.isEmpty(urlQueryParams));

  useEffect(() => {
    const confirmation = new URL(window.location.href).searchParams.get('confirmation');
    const login = new URL(window.location.href).searchParams.get('login');
    const passwordUpdate = new URL(window.location.href).searchParams.get('password_update');

    if (confirmation) {
      toast(formatMessage('mapbiomas.toast.account_confirmed'));
    }

    if (login) {
      toast(formatMessage('mapbiomas.toast.login_success'));
    }

    if (passwordUpdate) {
      toast(formatMessage('mapbiomas.toast.password_update'));
    }
  }, []);

  useEffect(() => {
    if (activeModuleContent !== 'coverage:coverage_changes') {
      updateClientAppState({
        showBeforeAndAfterMosaic: false,
      });
    }

    const checkModuleKeyCondition = (moduleKey) => {
      if (_.isUndefined(prevActiveModuleContent)) {
        return activeModuleContent === moduleKey;
      } else {
        return activeModuleContent === moduleKey &&
          !isOnInitialRenderingWithQueryParams;
      }
    };

    if (checkModuleKeyCondition('coverage:coverage_main')) {
      updateClientAppState({
        activeBaseMap: 9,
        layersOpacity: 70,
        activeLayers: [],
      });
    } else if (checkModuleKeyCondition('quality_of_pasture_data:quality_of_pasture_data_main')) {
      updateClientAppState({
        activeBaseMap: 6,
        layersOpacity: 100,
        activeLayers: ['estados']
      });
    } else if (checkModuleKeyCondition('regeneration:regeneration_annual') || checkModuleKeyCondition('deforestation:deforestation_annual')) {
      updateClientAppState({
        activeBaseMap: 1,
      });
    } else if (checkModuleKeyCondition('irrigation:irrigation_main')) {
      updateClientAppState({
        activeBaseMap: 1,
        activeLayers: ['estados']
      });
    } else if (checkModuleKeyCondition('infrastructure:infrastructure_main')) {
      updateClientAppState({
        activeBaseMap: 1,
        activeYear: 2020,
        timelineLimitsRange: [2000, 2023],
      })
    } else if (checkModuleKeyCondition('fire:fire_accumulated')) {
      updateClientAppState({
        activeBaseMap: 1,
        activeYear: [2000, 2023],
        timelineLimitsRange: [2000, 2023],
      });
    } else if (checkModuleKeyCondition('fire:fire_annual')) {
      updateClientAppState({
        activeBaseMap: 1,
        activeYear: [2000, 2023],
        timelineLimitsRange: [2000, 2023],
      });
    } else if (checkModuleKeyCondition('fire:fire_frequency')) {
      updateClientAppState({
        activeBaseMap: 1,
        activeYear: [2000, 2023],
        timelineLimitsRange: [2000, 2023],
      });
    } else if (checkModuleKeyCondition('fire:fire_monthly')) {
      updateClientAppState({
        activeBaseMap: 1,
        activeYear: 2023,
        timelineLimitsRange: [2000, 2023],
      });
    } else if (checkModuleKeyCondition('trajectories:trajectories_number_of_classes')) {
      updateClientAppState({
        activeBaseMap: 1,
        activeYear: [2000, 2023],
        timelineLimitsRange: [2000, 2023],
      });
    }

    const parsedModuleContentKey = _.last(_.split(activeModuleContent, ':'));

    if (parsedModuleContentKey) {
      setActiveNoteKey(parsedModuleContentKey);
    }

    setStatisticsData(null);
  }, [activeModuleContent]);

  useEffect(() => {
    if (isOnInitialRenderingWithQueryParams) {
      return;
    }

    if (showBeforeAndAfterMosaic) {
      updateClientAppState({
        activeBaseMap: 8,
        layersOpacity: 50,
      });
    } else {
      updateClientAppState({
        layersOpacity: 100
      });
    }
  }, [showBeforeAndAfterMosaic]);

  useEffect(() => {
    if (activeModule !== 'coverage' && mapboxMode) {
      toggleMapbox();
    }
  }, [activeModule]);

  useEffect(() => {
    const minYear = _.first(timelineLimitsRange);
    const maxYear = _.last(timelineLimitsRange);

    if (!_.isArray(activeYear) && (activeYear < minYear || activeYear > maxYear)) {
      updateClientAppState({
        activeYear: maxYear
      });
    }
  }, [timelineLimitsRange]);

  useEffect(() => {
    if (isMobile) {
      updateClientAppState({
        headerIsVisible: false,
      });
    }
  }, [isMobile]);

  useEffect(() => {
    if (territoryCategoriesList) {
      const countryTerritoryCategory = _.find(territoryCategoriesList, { key: 'country' });
      const countryTerritoryId = _.get(countryTerritoryCategory, 'id');
      loadCountryTerritoryData({
        variables: {
          categoryId: countryTerritoryId
        }
      });
    }
  }, [territoryCategoriesList]);

  const updateClientAppState = (params) => {
    client.writeData({
      data: {
        app: {
          __typename: 'AppState',
          ...params
        }
      }
    });
  };

  const closeClassLegendDialog = () => {
    updateClientAppState({ showClassInfo: null });
  };

  const closeDashboardDialog = () => {
    updateClientAppState({ showDashboardInfo: null });
  };

  const closeMapInfoDialog = () => {
    updateClientAppState({ mapPointInfo: null });
  };

  const handleMapPointSearchClick = (mapPointInfo) => {
    updateClientAppState({ mapPointInfo });
  };

  const handleYearTimelineChange = (year) => {
    updateClientAppState({ activeYear: year });
  };

  const handleFlyToPointSubmit = (coordinates) => {
    updateClientAppState({ mapFlyTo: coordinates });
  };

  const handleFlyToReset = () => {
    updateClientAppState({ mapFlyTo: null });
  };

  const handleControlsChange = (paramKey, paramValue) => {
    updateClientAppState({ [paramKey]: paramValue });
  };

  const handleMoveEnd = (value) => {
    if (value !== mapPosition) {
      updateClientAppState({ mapPosition: value });
    }
  };

  const openCoverageTutorial = () => {
    setCoverageTutorial(true)
  }

  const closeTutorial = () => {
    setCoverageTutorial(false)
  }

  const handleStatisticsShowData = (data) => {
    setStatisticsData(data);
  };

  const handleStatisticsCloseData = () => {
    setStatisticsData(null);
  };

  const toggleMapbox = () => {
    updateClientAppState({ mapboxMode: !mapboxMode });
  };

  const closeNoteDialog = () => {
    setActiveNoteKey(null);
    setReadNoteKeys([...readNoteKeys, activeNoteKey]);
  };

  const renderNoteDialog = () => {
    const availableLocaleNoteKeys = [
      'coverage_main',
      'coverage_changes',
      'coverage_quality_main',
      'quality_of_pasture_data_main',
      'irrigation_main',
      'trajectories_stable_areas',
      'deforestation_annual',
      'regeneration_annual',
      'fire_annual',
      'mining_main',
      'trajectories_number_of_classes',
    ];

    if (
      _.isNull(activeNoteKey) ||
      _.includes(readNoteKeys, activeNoteKey) ||
      !_.includes(availableLocaleNoteKeys, activeNoteKey)
    ) {
      return null;
    }

    return (
      <Dialog onClose={ closeNoteDialog } open={ !!activeNoteKey }>
        <div className={ styles.dialogWrapper }>
          <header className={ styles.header }>
            <h2 className={ styles.title }>
              <FormattedMessage id={ `mapbiomas.warning.${ activeNoteKey }.title` } />
            </h2>
            <IconButton className={ styles.closeButton } onClick={ closeNoteDialog }>
              <CloseIcon />
            </IconButton>
          </header>
          <div className={ styles.content }>
            <div
              className={ styles.contentDescription }
              dangerouslySetInnerHTML={{
                __html: formatMessage(`mapbiomas.warning.${ activeNoteKey }.body`)
              }}
            />
            <div className={ styles.actions }>
              <Button color="primary" variant="contained" onClick={ closeNoteDialog }>
                <FormattedMessage id={ `mapbiomas.warning.${ activeNoteKey }.button.confirm` } />
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  const TimelineComponent = activeModule !== 'trajectories' ? YearTimeline : YearTimelineMultiple;

  return (
    <Fragment>
      { !mapboxMode &&
        <MapCanvas
          activeBaseMap={ activeBaseMap }
          activeModuleContent={ activeModuleContent }
          activeYear={ activeYear }
          baseParams={ baseParams }
          configOptions={ mapConfigOptions }
          countryTerritoryId={ _.get(countryTerritoryListData, 'territoriesList[0].id') }
          defaultClassTreeLevelsList={ defaultClassTreeLevelsList }
          flyTo={ mapFlyTo }
          isMobile={ isMobile }
          mapPointInfo={ mapPointInfo }
          activeLayers={ activeLayers }
          layersOpacity={ layersOpacity }
          headerIsVisible={ headerIsVisible }
          onControlsChange={ handleControlsChange }
          onSearchPointClick={ handleMapPointSearchClick }
          showBeforeAndAfterMosaic={ showBeforeAndAfterMosaic }
          showDashboardInfo={ showDashboardInfo }
          setCoverageTutorial={ setCoverageTutorial }
          onMoveEnd={ handleMoveEnd }
          onFlyToReset={ handleFlyToReset }
          mapboxMode={ mapboxMode }
        />
      }
      { mapboxMode &&
        <MapboxCanvas
          activeLayers={ activeLayers }
          activeYear={ activeYear }
          baseParams={ baseParams }
          configOptions={ mapConfigOptions }
          flyTo={ mapFlyTo }
          headerIsVisible={ headerIsVisible }
          isMobile={ isMobile }
          layersOpacity={ layersOpacity }
          onSearchPointClick={ handleMapPointSearchClick }
          onMoveEnd={ handleMoveEnd }
        />
      }
      { !(showDashboardInfo && isMobile) &&
        <MapControls
          activeBaseMap={ activeBaseMap }
          showBeforeAndAfterMosaic={ showBeforeAndAfterMosaic }
          activeLayers={ activeLayers }
          headerIsVisible={ headerIsVisible }
          layersOpacity={ layersOpacity }
          onChange={ handleControlsChange }
        />
      }
      { !showBeforeAndAfterMosaic &&
        !(showDashboardInfo && isMobile) &&
        <TimelineComponent
          headerIsVisible={ headerIsVisible }
          value={ activeYear }
          onChange={ handleYearTimelineChange }
        />
      }
      <CoordinatesBox
        headerIsVisible={ headerIsVisible }
        showBeforeAndAfterMosaic={ showBeforeAndAfterMosaic }
        onSubmit={ handleFlyToPointSubmit }
      />
      { activeModuleContent === 'coverage:coverage_main' &&
        <MapboxControl
          isActive={ mapboxMode }
          onToggle={ toggleMapbox }
          headerIsVisible={ headerIsVisible }
          showBeforeAndAfterMosaic={ showBeforeAndAfterMosaic }
        />
      }
      <ShareControl
        headerIsVisible={ headerIsVisible }
        showBeforeAndAfterMosaic={ showBeforeAndAfterMosaic }
      />
      <ClassLegendDialog
        headerIsVisible={ headerIsVisible }
        isOpen={ !!showClassInfo }
        classId={ showClassInfo }
        onClose={ closeClassLegendDialog }
      />
      <MapPointDialog
        headerIsVisible={ headerIsVisible }
        isOpen={ !!mapPointInfo }
        pointInfo={ mapPointInfo }
        onClose={ closeMapInfoDialog }
      />
      <DashboardDialog
        activeModuleContent={ activeModuleContent }
        activeYear={ activeYear }
        baseParams={ baseParams }
        classTreeKey={ activeClassTreeOptionValue }
        headerIsVisible={ headerIsVisible }
        isOpen={ showDashboardInfo }
        onClose={ closeDashboardDialog }
      />
      { (activeModule === 'fire' ||
         activeModule === 'deforestation' ||
         activeModule === 'regeneration' ||
         activeModule === 'trajectories' ||
         activeModuleContent === 'coverage:coverage_main' ||
         activeModuleContent === 'coverage:coverage_stable_areas' ||
         activeModuleContent === 'coverage:coverage_number_of_classes' ||
         activeModuleContent === 'mining:mining_main' ||
         activeModuleContent === 'quality_of_pasture_data:quality_of_pasture_data_main' ||
         activeModuleContent === 'irrigation:irrigation_main'
      ) &&
        <StatisticsBox
          activeModuleContent={ activeModuleContent }
          activeYear={ activeYear }
          baseParams={ baseParams }
          classTreeKey={ activeClassTreeOptionValue }
          statisticsDataIsVisible={ !!statisticsData }
          headerIsVisible={ headerIsVisible }
          onShowData={ handleStatisticsShowData }
        />
      }
      { activeModuleContent === 'infrastructure:infrastructure_main' &&
        <InfrastructureStatisticsBox
          activeYear={ activeYear }
          baseParams={ baseParams }
          statisticsDataIsVisible={ !!statisticsData }
          headerIsVisible={ headerIsVisible }
          onShowData={ handleStatisticsShowData }
        />
      }
      <StatisticsDataTableBox
        headerIsVisible={ headerIsVisible }
        data={ statisticsData }
        onClose={ handleStatisticsCloseData }
      />

      { renderNoteDialog() }

      {/* <TutorialTour currentModule={activeModule} closeTutorial={closeTutorial} runTutorial={runTutorial} /> */}
    </Fragment>
  );
}
