export default [
  {
    index: 0,
    key: "political_level_1",
    labelKey: "political_level_1",
    color: "#b15928",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-bolivia:pais"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IGM (ajustado por FAN-RAISG)' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/bolivia/collection_1/download/layers/pais.zip'
  },
  {
    index: 1,
    key: "political_level_2",
    labelKey: "political_level_2",
    color: "#ffff99",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-bolivia:departamento"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IGM (ajustado por FAN-RAISG)' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/bolivia/collection_1/download/layers/departamento.zip'
  },
  {
    index: 2,
    key: "political_level_3",
    labelKey: "political_level_3",
    color: "#6a3d9a",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-bolivia:municipio"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IGM (ajustado por FAN-RAISG)' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/bolivia/collection_1/download/layers/municipio.zip'
  },
  {
    index: 3,
    key: "biome",
    labelKey: "biome",
    color: "#ff7f00",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-bolivia:bioma-pais"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'FAN-RAISG' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/bolivia/collection_1/download/layers/bioma-pais.zip'
  },
  {
    index: 4,
    key: "basin_level_1",
    labelKey: "basin_level_1",
    color: "#fdbf6f",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-bolivia:cuenca-hidrografica-nivel1"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'VMMAyA (ajustado por FAN-RAISG)' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/bolivia/collection_1/download/layers/cuenca-hidrografica-nivel1.zip'
  },
  {
    index: 5,
    key: "basin_level_2",
    labelKey: "basin_level_2",
    color: "#FF0000",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-bolivia:cuenca-hidrografica-nivel2"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'VMMAyA (ajustado por FAN-RAISG)' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/bolivia/collection_1/download/layers/cuenca-hidrografica-nivel2.zip'
  },
  {
    index: 6,
    key: "peasant_original_indigenous_territory",
    labelKey: "peasant_original_indigenous_territory",
    color: "#ae017e",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-bolivia:territorio-indigena-titulado"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'INRA' },
      { labelKey: 'year', label: 'Ano', value: '2018' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/bolivia/collection_1/download/layers/territorio-indigena-titulado.zip'
  },
  {
    index: 7,
    key: "national_protected_areas",
    labelKey: "national_protected_areas",
    color: "#a6cee3",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-bolivia:area-protegida-nacional"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'SERNAP' },
      { labelKey: 'year', label: 'Ano', value: '2018' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/bolivia/collection_1/download/layers/area-protegida-nacional.zip'
  },
  {
    index: 8,
    key: "sub_national_protected_areas",
    labelKey: "sub_national_protected_areas",
    color: "#f768a1",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-bolivia:area-protegida-subnacional"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'FAN-RAISG' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/bolivia/collection_1/download/layers/area-protegida-subnacional.zip'
  },
  {
    index: 9,
    key: "ramsar_site",
    labelKey: "ramsar_site",
    color: "#bf812d",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-bolivia:sitios-ramsar"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'WWF' },
      { labelKey: 'year', label: 'Ano', value: '2013' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/bolivia/collection_1/download/layers/sitios-ramsar.zip'
  },
  {
    index: 10,
    key: "ecoregion",
    labelKey: "ecoregion",
    color: "#c7eae5",
    props: {
      url: "http://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-bolivia:ecorregion"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Ibisch et al.' },
      { labelKey: 'year', label: 'Ano', value: '2003' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/bolivia/collection_1/download/layers/ecorregion.zip'
  },
];
