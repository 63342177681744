import React, { Fragment } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';

import styles from './ClassesControl.module.scss';

const useStyles = makeStyles((theme) => ({
  activeButton: {
    color: `${ theme.palette.primary.main } !important`,
    borderColor: `${ theme.palette.primary.main } !important`
  },
  link: {
    color: `${ theme.palette.primary.main } !important`,
  },
}));

export default function ClassesControl({
  activeClassificationLevels,
  disabled = false,
  viewMode,
  levels = [],
  hideLevelsToggle = false,
  viewModeOptions,
  showDescription = false,
  onClassificationLevelToggle = () => {},
  onViewModeChange = () => {},
  titleKey,
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  const intl = useIntl()

  const renderLevelCheckboxesToggle = () => {
    return _.map(levels, (levelId) => {
      const isChecked = _.includes(activeClassificationLevels, levelId);

      return (
        <FormControlLabel
          key={ `level-control-${ levelId }` }
          disabled={ disabled }
          classes={{
            root: styles.checkboxRoot,
            label: styles.checkboxLabel,
          }}
          control={
            <Checkbox
              checked={ isChecked }
              onChange={ onClassificationLevelToggle.bind(this, levelId) }
              name={ `level-${ levelId }` }
              color="primary"
              size="small"
            />
          }
          label={ `${ formatMessage('mapbiomas.header.classes_control.level.title') } ${ levelId }` }
        />
      );
    })
  };

  return (
    <div className={ styles.wrapper }>
      <h2 className={ styles.title }><FormattedMessage id={ titleKey || 'mapbiomas.header.classes_control.title' } /></h2>
      { showDescription && <p className={ styles.descriptionText }><FormattedMessage id="mapbiomas.header.classes_control.description.click" /> <a href={intl.formatMessage({id: 'mapbiomas.header.classes_control.description.url'})} target="_blank" className={ classes.link }><FormattedMessage id="mapbiomas.header.classes_control.description.here" /></a> <FormattedMessage id="mapbiomas.header.classes_control.description.content" /></p> }
      { viewModeOptions &&
        <Fragment>
          <span className={ styles.label }><FormattedMessage id="mapbiomas.header.classes_control.view_mode.title" /></span>
          <div className={ styles.toggleButtons }>
            { _.map(viewModeOptions, (option, index) => {
              return (
                <button
                  id={`legend-t-${index}`}
                  className={ classnames(styles.actionButton, {
                    [classes.activeButton]: viewMode === option.id,
                    [styles.disabledButton]: disabled,
                  }) }
                  key={ `view-mode-${ option.id }` }
                  onClick={ onViewModeChange.bind(this, option.id) }
                >
                  <FormattedMessage id={ `mapbiomas.header.classes_control.view_mode.options.${ option.labelKey }` } />
                </button>
              );
            }) }
          </div>
        </Fragment>
      }
      { !hideLevelsToggle &&
        <div className={ styles.levelControls }>
          { renderLevelCheckboxesToggle() }
        </div>
      }
    </div>
  );
}
