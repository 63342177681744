import { gql } from 'graphql.macro';

export const GET_BASE_DATA = gql`
  {
    app @client {
      activeBaseMap
      activeLayers
      layersOpacity
      activeModule
      activeModuleContent
      activeYear
      mapPosition
      showBeforeAndAfterMosaic
      isLoadingBaseData
      notifyUserNewTermOfUseVersion
      timelineLimitsRange
      baseParams {
        groupType
        territoryType
        territorySubtype
        territoryValueType
        territory
        territories {
          id
          label
          territoryTypeId
          territoryTypeLabel
          territoryBBox
        }
        crossing
        classesViewMode
        activeClassTreeOptionValue
        activeClassTreeNodeIds
        activeObjectTreeNodeIds
        activeSubmodule
        activeClassesLevelsListItems
        activeTransitionsClassesListItems
        activePastureQualityQualityClassesListItems
        yearRange
        buffer
      }
    }
  }
`;
