export default {
  'territorial': [
    'country',
    'biome',
    'state',
    'city',
    'watershed',
    'sub_watershed',
  ],
  'land': [
    'peasant_original_indigenous_territory',
    'national_protected_areas',
    'subnational_protected_areas',
    'ramsar_site',
    'ecoregion',
  ]

};