import { gql } from 'graphql.macro';

export const GET_BASE_DATA = gql`
  {
    app @client {
      activeBaseMap
      activeLayers
      layersOpacity
      activeModule
      activeModuleContent
      activeYear
      headerIsVisible
      mapFlyTo
      mapPointInfo {
        latlng
        parsedBBox
      }
      showClassInfo
      showDashboardInfo
      showBeforeAndAfterMosaic
      baseParams {
        groupType
        territoryType
        territorySubtype
        territory
        territories {
          id
          label
          territoryTypeId
          territoryTypeLabel
          territoryBBox
        }
        crossing
        classesViewMode
        activeClassesLevelsListItems
        activeTransitionsClassesListItems
        activePastureQualityQualityClassesListItems
        yearRange
      }
    }
  }
`;
