import React from 'react'
import Joyride from 'react-joyride';
import { FormattedMessage, injectIntl } from 'react-intl';

class TutorialTour extends React.Component {
  // TODO: Update tutorial steps
  state = {
    steps: [
      {
        target: '#modules',
        content: <FormattedMessage id="mapbiomas.tutorial.modules" />,
        disableBeacon: true,
      },
      {
        target: '#module-main-options',
        content: <FormattedMessage id="mapbiomas.tutorial.module-main-options" />,
        disableBeacon: true,
      },
      {
        target: '#translation-button',
        content: <FormattedMessage id="mapbiomas.tutorial.translation-button" />,
        disableBeacon: true,
      },
      {
        target: '#logo',
        content: <FormattedMessage id="mapbiomas.tutorial.logo" />,
        disableBeacon: true,
      },
      {
        target: '#toggle-header-button',
        content: <FormattedMessage id="mapbiomas.tutorial.toggle-header-button" />,
        disableBeacon: true,
      },
      {
        target: '#coverage_main',
        content: <FormattedMessage id="mapbiomas.tutorial.coverage-main" />,
        disableBeacon: true,
      },
      {
        target: '#coverage_changes',
        content: <FormattedMessage id="mapbiomas.tutorial.coverage-changes" />,
        disableBeacon: true,
      },
      {
        target: '#coverage_quality',
        content: <FormattedMessage id="mapbiomas.tutorial.coverage-quality" />,
        disableBeacon: true,
      },
      {
        target: '#territorial-cut',
        content: <FormattedMessage id="mapbiomas.tutorial.territorial-cut" />,
        disableBeacon: true
      },
      {
        target: '#landowner-cut',
        content: <FormattedMessage id="mapbiomas.tutorial.landowner-cut" />,
        disableBeacon: true
      },
      {
        target: '#multiple-territory-checkbox',
        content: <FormattedMessage id="mapbiomas.tutorial.multiple-territory" />,
        disableBeacon: true
      },
      {
        target: '#legend-t-0',
        content: <FormattedMessage id="mapbiomas.tutorial.legend-class" />,
        disableBeacon: true
      },
      {
        target: '#legend-t-1',
        content: <FormattedMessage id="mapbiomas.tutorial.legend-natural-use" />,
        disableBeacon: true
      },
      {
        target: '#share-button',
        content: <FormattedMessage id="mapbiomas.tutorial.share-button" />,
        disableBeacon: true
      },
      {
        target: '.leaflet-control-zoom-in',
        content: <FormattedMessage id="mapbiomas.tutorial.control-zoom-in" />,
        disableBeacon: true
      },
      {
        target: '.leaflet-control-zoom-out',
        content: <FormattedMessage id="mapbiomas.tutorial.control-zoom-out" />,
        disableBeacon: true
      },
      {
        target: '#box-zoom-button',
        content: <FormattedMessage id="mapbiomas.tutorial.box-zoom" />,
        disableBeacon: true
      },
      {
        target: '#info-point',
        content: <FormattedMessage id="mapbiomas.tutorial.info-point" />,
        disableBeacon: true
      },
      {
        target: '#opacity-box',
        content: <FormattedMessage id="mapbiomas.tutorial.opacity-box" />,
        disableBeacon: true
      },
      {
        target: '#layers-box',
        content: <FormattedMessage id="mapbiomas.tutorial.layers-box" />,
        disableBeacon: true
      },
      {
        target: '#basemap-box',
        content: <FormattedMessage id="mapbiomas.tutorial.basemap-box" />,
        disableBeacon: true
      },
      {
        target: '#coordinates-search',
        content: <FormattedMessage id="mapbiomas.tutorial.coordinate-search" />,
        disableBeacon: true
      },
      {
        target: '#year-timeline',
        content: <FormattedMessage id="mapbiomas.tutorial.year-timeline" />,
        disableBeacon: true
      },
      {
        target: '#statistics',
        content: <FormattedMessage id="mapbiomas.tutorial.statistics" />,
        disableBeacon: true
      },
      {
        target: '#statistics-toggle',
        content: <FormattedMessage id="mapbiomas.tutorial.statistics-toggle" />,
        disableBeacon: true
      },
      {
        target: '#statistics-graphics',
        content: <FormattedMessage id="mapbiomas.tutorial.statistics-graphics" />,
        disableBeacon: true
      },
      {
        target: '#data-view-button',
        content: <FormattedMessage id="mapbiomas.tutorial.data-view-button" />,
        disableBeacon: true,
      },
    ],
    disableScrolling: true,
  };



  render () {
    const { steps } = this.state

    return (
      <div className="app">
        <Joyride
          steps={steps}
          callback={({action, index}) => {
            index === 26 && this.setState({
              ...this.state,
              disableScrolling: false,
            })
            action === "reset" && this.props.closeTutorial()
          }}
          run={this.props.coverageTutorial}
          showSkipButton={true}
          disableScrolling={this.state.disableScrolling}
          floaterProps={{
            disableAnimation: true
          }}
          locale={{
            close: <FormattedMessage id="mapbiomas.tutorial.controls.next" />,
            skip: <FormattedMessage id="mapbiomas.tutorial.controls.skip" />,
            back: <FormattedMessage id="mapbiomas.tutorial.controls.back" />
          }}
          styles={{
            options: {
              primaryColor: "#16a085",
              zIndex: 4000
            },
            buttonClose: {
              display: "none"
            },
            buttonBack:{
              marginRight: 16
            }
          }}
        />
      </div>
    );
  }
}

export default injectIntl(TutorialTour)
