import React, { useState } from 'react';
import classnames from 'classnames';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

import styles from './NaturalOrAnthropicLegendList.module.scss';

export default function NaturalOrAnthropicLegendList({
  activeClassesLevelsListItems,
  parsedClassesLevelsList,
  viewMode,
  onClassificationLevelToggleByParentId = () => {},
  onItemsChange = () => {},
}) {
  const [expandedItems, setExpandedItems] = useState([]);

  const itemIsActive = (level, id) => {
    if (level !== 1) {
      return _.includes(activeClassesLevelsListItems, id);
    } else {
      const childItems = _.filter(parsedClassesLevelsList, { parentId: id });
      const childItemsIds = _.map(childItems, 'id');

      const childItemsIdsActive = _(childItemsIds)
        .filter((id) => _.includes(activeClassesLevelsListItems, id))
        .compact()
        .value();
      
      return _.size(childItemsIds) === _.size(childItemsIdsActive);
    }
  };

  const toggleActiveItem = (level, id) => {
    if (level !== 1) {
      if (itemIsActive(level, id)) {
        let itemsClone = _.clone(activeClassesLevelsListItems);
        itemsClone = _.filter(itemsClone, (itemId) => itemId !== id);
  
        onItemsChange(itemsClone);
      } else {
        onItemsChange([
          ...activeClassesLevelsListItems,
          id
        ]);
      }
    } else {
      onClassificationLevelToggleByParentId(id, !itemIsActive(1, id))
    }
  };

  const toggleExpandedItem = (id) => {
    if (_.includes(expandedItems, id)) {
      let itemsClone = _.clone(expandedItems);
      itemsClone = _.filter(itemsClone, (itemId) => itemId !== id);

      setExpandedItems(itemsClone);
    } else {
      setExpandedItems([
        ...expandedItems,
        id
      ]);
    }
  };

  const renderList = (level, parentId, baseIndex) => {
    const filteredItems = _.filter(parsedClassesLevelsList, (item) => {
      if (parentId) {
        return item.level === level && item.parentId === parentId;
      } else {
        return item.level === level;
      }
    });

    return (
      <ul className={ classnames(styles.classList, {
        [styles.classListLevel2]: level === 2,
        [styles.classListLevel3]: level === 3,
      }) }>
        { _.map(_.sortBy(filteredItems, 'position'), (item, index) => {
          const isExpanded = _.includes(expandedItems, item.id);
          const hasChildItems = !!_.find(parsedClassesLevelsList, { level: level + 1, parentId: item.id });
          const indexLabel = baseIndex ? `${ baseIndex }.${ index + 1 }` : `${ index + 1 }`;

          return (
            <li
              key={ `class-item-level-${ item.id }` }
              className={ styles.classListItemBase } 
            >
              <div className={ styles.classListItemHeader }>
                <IconButton
                  className={ styles.classListItemCheckControl }
                  style={{ color: item.color }}
                  onClick={ toggleActiveItem.bind(this, level, item.id) }
                >
                  { itemIsActive(level, item.id) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> }
                </IconButton>
                <span className={ styles.classListItemTitle }>{ item.label }</span>
                { level !== 3 && hasChildItems &&
                  <IconButton
                    className={ styles.classListItemContentControl }
                    onClick={ toggleExpandedItem.bind(this, item.id) }
                  >
                    { isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                  </IconButton>
                }
                {/* { level === 3 &&
                  <IconButton
                    className={ styles.classListItemInfoControl }
                    onClick={ onShowInfo.bind(this, item.id) }
                  >
                    <InfoIcon />
                  </IconButton>
                } */}
              </div>
              <Collapse in={ isExpanded }>
                <div>
                  { renderList(level + 1, item.id, indexLabel) }
                </div>
              </Collapse>
            </li>
          );
        }) }
      </ul>
    );
  };

  return (
    <div>
      { renderList(1) }
    </div>
  );
}
