import React, { useEffect } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

import ActiveOptionsList from '../ActiveOptionsList';

import CustomSelect from '../../../../components/CustomSelect';

import useFormatMessage from '../../../../../../hooks/useFormatMessage';

import mapGroupTypeToTerritoryCategoryKey from '../../../../../../data/mapGroupTypeToTerritoryCategoryKey';

import styles from './QualityBaseForm.module.scss';

import {
  GET_BASE_DATA,
  GET_TERRITORIES_LIST,
} from './query';

const CustomTooltip = withStyles(() => ({
  tooltip: {
    padding: '8px 12px',
    fontSize: 14,
    color: 'white',
    backgroundColor: '#555',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12)',
  },
}))(Tooltip);

const defaultCrossingKeys = [
  'indigenous_land',
  'settlement',
  'quilombo',
  'state_conservation_units_integral_protection',
  'federal_conservation_units_integral_protection',
  'state_conservation_units_sustainable_use',
  'federal_conservation_units_sustainable_use',
];

export default function QualityBaseForm({
  inline,
  onFlyTo = () => {},
}) {
  const locale = localStorage.getItem('locale') || 'es';
  const client = useApolloClient();
  const formatMessage = useFormatMessage();
  const { data: baseData, loading: loadingBaseData } = useQuery(GET_BASE_DATA);
  const [
    loadTerritoriesListData,
    {
      data: territoriesListData,
      loading: loadingTerritoriesListData
    }
  ] = useLazyQuery(GET_TERRITORIES_LIST);
  const baseParams = _.get(baseData, 'app.baseParams');
  const territoryCategoriesList = _.get(baseData, 'territoryCategoriesList');
  const territoriesList = _.get(territoriesListData, 'territoriesList');

  const groupType = _.get(baseParams, 'groupType') || 'territorial';
  const territoryType = _.get(baseParams, 'territoryType');
  const territoryValueType = _.get(baseParams, 'territoryValueType');
  const territoryIsMultiple = territoryValueType === 'multiple';
  const territories = _.get(baseParams, 'territories');

  const parsedTerritoryTypeOptions = _.map(territoryCategoriesList, (territoryType) => {
    const stringList = _.get(territoryType, 'i18nStrings');
    const selectedString = _.find(stringList, { language: locale });

    return {
      id: territoryType.id,
      key: territoryType.key,
      label: _.get(selectedString, 'stringValue'),
      parentId: territoryType.parentId,
    };
  });
  const territoryKeysByGroupType = mapGroupTypeToTerritoryCategoryKey[groupType];
  const filteredTerritoryTypeOptions = _(parsedTerritoryTypeOptions)
    .filter(({ key }) => _.includes(territoryKeysByGroupType, key))
    .sortBy(({ key }) => _.findIndex(territoryKeysByGroupType, (baseTerritoryKey) => baseTerritoryKey === key))
    .value();
  const parsedTerritoryOptions = _.map(territoriesList, (territory) => {
    const stringList = _.get(territory, 'i18nStrings');
    const selectedString = _.find(stringList, { language: locale });
    const boundingBox = _.get(territory, 'territoryGeometry.boundingBox');

    return {
      id: territory.id,
      label: _.get(selectedString, 'stringValue'),
      boundingBox: [
        [_.get(boundingBox, 'yMin'), _.get(boundingBox, 'xMin')],
        [_.get(boundingBox, 'yMax'), _.get(boundingBox, 'xMax')],
      ]
    };
  });

  const updateClientAppBaseParamsState = (params) => {
    client.writeData({
      data: {
        app: {
          __typename: 'AppState',
          baseParams: {
            __typename: 'AppBaseParamsState',
            ...params
          }
        }
      }
    });
  };

  useEffect(() => {
    if (territoryType) {
      loadTerritoriesListData({
        variables: {
          categoryId: territoryType
        }
      });
    }
  }, [territoryType]);

  useEffect(() => {
    if (territoryCategoriesList) {
      const initialTerritoryType = _.find(territoryCategoriesList, { key: 'country' });
      const territoryTypeId = _.get(initialTerritoryType, 'id');
      const params = {
        territoryType: territoryTypeId,
      };
      loadTerritoriesListData({
        variables: {
          categoryId: territoryTypeId
        }
      });

      updateClientAppBaseParamsState(params);
    }
  }, [territoryCategoriesList]);

  useEffect(() => {
    if (territoriesList && _.size(territoriesList) === 1) {
      const territoryTypeOption = _.find(filteredTerritoryTypeOptions, { id: territoryType });

      const firstTerritory = _.first(territoriesList);
      const firstTerritoryId = _.get(firstTerritory, 'id');
      const territoryOption = _.find(parsedTerritoryOptions, { id: firstTerritoryId });

      const activeTerritoryIds = _.map(territories, 'id');

      if (!_.includes(activeTerritoryIds, firstTerritoryId)) {
        const newTerritoryItem = {
          __typename: 'AppBaseParamsActiveTerritoryItemState',
          id: firstTerritoryId,
          label: _.get(territoryOption, 'label'),
          territoryTypeId: _.get(territoryTypeOption, 'id'),
          territoryTypeLabel: _.get(territoryTypeOption, 'label'),
          territoryBBox: _.get(territoryOption, 'boundingBox'),
        };

        updateClientAppBaseParamsState({
          territories: _.concat(_.isNil(territories) ? [] : territories, newTerritoryItem)
        });
        onFlyTo(_.get(territoryOption, 'boundingBox'));
      }
    }
  }, [territoriesList]);

  const handleInputChange = (field, inputValue) => {
    const value = _.isObject(inputValue) ? inputValue.target.value : inputValue;
    let params = { [field]: value };

    if (field === 'territoryType' && !territoryIsMultiple) {
      params.territories = [];
    }

    updateClientAppBaseParamsState(params);
  };


  const handleGroupTypeChange = (event) => {
    let params = {
      groupType: event.target.value,
      territoryType: null,
    };

    if (!territoryIsMultiple) {
      params.territories = [];
    }

    updateClientAppBaseParamsState(params);
  };

  const handleTerritoryChange = (field, value) => {
    const parsedValue = _.isObject(value) ? value.target.value : value;
    const baseOption = _.find(parsedTerritoryOptions, { id: parsedValue });
    const territoryTypeOption = _.find(filteredTerritoryTypeOptions, { id: territoryType });
    const activeTerritoryIds = _.map(territories, 'id');

    if (!_.includes(activeTerritoryIds, _.get(baseOption, 'id'))) {
      const newTerritoryItem = {
        __typename: 'AppBaseParamsActiveTerritoryItemState',
        id: _.get(baseOption, 'id'),
        label: _.get(baseOption, 'label'),
        territoryTypeId: _.get(territoryTypeOption, 'id'),
        territoryTypeLabel: _.get(territoryTypeOption, 'label'),
        territoryBBox: _.get(baseOption, 'boundingBox'),
      };

      let nextTerritories = _.concat(_.isNil(territories) ? [] : territories, newTerritoryItem);

      if (!territoryIsMultiple) {
        nextTerritories = [newTerritoryItem];
      }

      updateClientAppBaseParamsState({
        territories: nextTerritories
      });
    }

    if (baseOption) {
      onFlyTo(baseOption.boundingBox);
    }

    handleInputChange(field, value);
  };

  const handleRemoveTerritory = (id) => {
    let territoriesClone = _.cloneDeep(territories);
    territoriesClone = _.filter(territoriesClone, (territory) => territory.id !== id);

    updateClientAppBaseParamsState({ territories: territoriesClone });
  };

  const toggleTerritoryValueType = () => {
    if (territoryIsMultiple) {
      updateClientAppBaseParamsState({
        territoryValueType: 'single',
        territories: [],
      });
    } else {
      updateClientAppBaseParamsState({ territoryValueType: 'multiple' });
    }
  };

  return (
    <div className={ classnames(styles.wrapper, {
      [styles.wrapperInline]: inline
    }) }>
      <RadioGroup
        name="groupType"
        value={ groupType || null }
        onChange={ handleGroupTypeChange }
        className={ styles.radioGroup }
      >
        <FormControlLabel
          classes={ {
            label: styles.radioLabel
          } }
          value="territorial"
          control={ <Radio className={ styles.radioInput } size="small" color="primary" /> }
          label={ formatMessage(`mapbiomas.header.base_form.group_type.options.territorial`) }
        />
        <FormControlLabel
          classes={ {
            label: styles.radioLabel
          } }
          value="land"
          control={ <Radio className={ styles.radioInput } size="small" color="primary" /> }
          label={ formatMessage(`mapbiomas.header.base_form.group_type.options.land`) }
        />
      </RadioGroup>
      <FormControl
        variant="outlined"
        className={ styles.formControl }
      >
        <CustomSelect
          defaultOrder
          options={ filteredTerritoryTypeOptions }
          value={ territoryType }
          dataKey="territoryType"
          label={ formatMessage(`mapbiomas.header.base_form.territory_type.title.${ groupType }`) }
          onChange={ handleInputChange }
          placeholder={ formatMessage(`mapbiomas.header.base_form.territory.placeholder`) }
        />
      </FormControl>
      <FormControl
        variant="outlined"
        className={ styles.formControl }
      >
        <CustomSelect
          isMultiple={ territoryIsMultiple }
          isLoading={ loadingBaseData || loadingTerritoriesListData }
          options={ parsedTerritoryOptions }
          value={ territoryIsMultiple ? null : _.get(_.first(territories), 'id') }
          dataKey="territory"
          label={ formatMessage(`mapbiomas.header.base_form.territory.title.${ groupType }`) }
          onChange={ handleTerritoryChange }
          placeholder={ formatMessage(`mapbiomas.header.base_form.territory.placeholder`) }
        />
        { territoryIsMultiple &&
          <ActiveOptionsList
            categoryKey="territoryTypeLabel"
            options={ territories }
            onClear={ handleRemoveTerritory }
          />
        }
      </FormControl>
      <FormControlLabel
        classes={ {
          root: styles.checkboxRoot,
          label: styles.checkboxLabel,
        } }
        control={
          <Checkbox
            checked={ territoryIsMultiple }
            onChange={ toggleTerritoryValueType }
            name="territoryValueType"
            color="primary"
            size="small"
          />
        }
        label={ (
          <span className={ styles.tooltipWrapper }>
            <span><FormattedMessage id="mapbiomas.header.base_form.territory_value_type.title" /></span>
            <CustomTooltip title={ formatMessage('mapbiomas.header.base_form.territory_value_type.tooltip') }>
              <InfoIcon />
            </CustomTooltip>
          </span>
        ) }
      />
    </div>
  );
}
