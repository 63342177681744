import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import { useIntl, FormattedMessage } from 'react-intl';

import BarChart from '../../components/BarChart';
import BaseValuesChart from '../../components/BaseValuesChart';

import InfoTooltip from '../../../../../../../../components/InfoTooltip';

import customClient from '../../../../../../../../core/custom-apollo-client/custom-apollo-client';

import styles from '../../StatisticsBox.module.scss';

import {
  GET_BASE_DATA,
  GET_CLASS_TREE_DATA,
} from './query';

export default function FrequencyStatisticsContent({
  clientType,
  ageMode = false,
  activeYear,
  baseParams,
  classTreeKey,
  statisticsDataIsVisible,
  onShowData,
}) {
  const locale = localStorage.getItem('locale') || 'es';

  const intl = useIntl()

  const mainYear = _.last(activeYear);
  const fromYear = _.first(activeYear);
  const toYear = _.last(activeYear);
  const years = [_.first(activeYear), _.last(activeYear)];
  const activeClassTreeNodeIds = _.get(baseParams, 'activeClassTreeNodeIds');
  const territories = _.get(baseParams, 'territories');
  const territoryIds = _.map(territories, 'id');

  const [chartsData, setChartsData] = useState(null);
  const [emptyData, setEmptyData] = useState(false);

  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    },
    client: clientType === 'custom' ? customClient : undefined
  });
  const classTreeLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

  const { data: baseData, loading, error: errorBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      classTreeNodeIds: activeClassTreeNodeIds,
      fromYear: ageMode ? toYear : fromYear,
      toYear,
      years: years,
      territoriesIds: territoryIds,
      locale: locale
    },
    client: clientType === 'custom' ? customClient : undefined
  });

  const frequencyByAreaData = () => {
    const data = _.get(baseData, 'frequencySeriesAggregated');

    const parsedValues = _.map(data, (item) => {
      const nodeId = _.get(item, 'label');
      const value = _.get(item, 'value');
      const nodeData = _.find(classTreeLevelsList, { id: Number(nodeId) });
      const stringList = _.get(nodeData, 'i18nStrings');
      const selectedString = _.find(stringList, { language: locale });
      const label = _.get(selectedString, 'stringValue');

      return {
        name: label,
        value,
        itemStyle: {
          color: _.get(nodeData, 'color') || '#e74c3c'
        },
        position: _.get(nodeData, 'positionInTree[0]')
      };
    });

    return _.sortBy(parsedValues, 'position');
  };

  const getTotalBurnedAreaByFrequencyAndClassData = () => {
    const newData = baseData['frequencySeriesAggregatedByClass'] || {}

    const categories = newData['classes'].map(({ label }) => label )

    const series = newData['burned'].map(({color, label, areas}) => {
      const collection = newData['classes'].map(({ id })=>{
        return areas[id] || null;
      })
      return {
        stack: 'classTreeNodeId',
        name: label,
        color: color,
        data: collection
      };
    });

    return {
      categories: categories,
      series: series,
    };
  };

  useEffect(() => {
    const sampleData = _.get(baseData, 'frequencySeriesAggregated');

    if ((!loading && errorBaseData) || _.isEmpty(sampleData)) {
      setEmptyData(true);
    } else {
      setEmptyData(false);
    }

    if (!loadingClassTreeData && !loading && baseData) {
      setChartsData({
        'frequencyByArea': frequencyByAreaData(),
        'frequencyByAreaAndClass': getTotalBurnedAreaByFrequencyAndClassData(),
      });
    }
  }, [baseData, loading, loadingClassTreeData, errorBaseData]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const areaByPeriod = () => {
    const dataAggregated = _.get(baseData, 'frequencyAggregated');

    const total = _.get(dataAggregated, 'total') || 0;
    const maxValue = _.get(dataAggregated, 'maximum') || 0;
    const minValue = _.get(dataAggregated, 'minimum') || 0;
    const averageValue = _.get(dataAggregated, 'average') || 0;
    const averagePercentage = (averageValue / maxValue) * 100;
    const minPercentage = (minValue / maxValue) * 100;

    const distByYears = _.get(baseData, 'frequencySeriesAggregated') || [];
    const maxYear = _.get(_.find(distByYears, { value: maxValue }), 'label');
    const minYear = _.get(_.find(distByYears, { value: minValue }), 'label');

    const customTotal = _.get(_.find(distByYears, { label: mainYear }), 'value') || null;
    const isMining = classTreeKey === 'mining_annual';

    return (
      <BaseValuesChart
        title={ !isMining ?  intl.formatMessage({id: 'mapbiomas.statistics.area.period'}) : `Área - ${ mainYear }` }
        totalTitle="Total"
        total={ !isMining ? total : customTotal }
        maxValue={ maxValue }
        maxYear={ maxYear }
        averageValue={ averageValue }
        averagePercentage={ averagePercentage }
        minValue={ minValue }
        minYear={ minYear }
        minPercentage={ minPercentage }
      />
    );
  };

  const frequencyByArea = () => {
    const values = _.get(chartsData, 'frequencyByArea') || [];

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }>
          <span>{ !ageMode ? intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.area_frequency.title'}) : intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.area_per_age.title'}) }</span>

          <InfoTooltip text={intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.title_period_tooltip'})} />
        </h2>

        <BarChart
          categories={ _.map(values, 'name') }
          series={ [
            {
              name: 'Total',
              data: values,
            }
          ] }
        />
      </div>
    );
  };

  const frequencyByAreaAndClass = () => {
    const categories = _.get(chartsData, 'frequencyByAreaAndClass.categories') || [];
    const series = _.get(chartsData, 'frequencyByAreaAndClass.series') || [];

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }>
          <span>
            {
              !ageMode
              ? intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.area_frequency.subtitle'})
              : intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.area_per_age.subtitle'})
            }
              <InfoTooltip text={intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.title_class_coverage_tooltip'})} />
          </span>
          <span className={ styles.secondaryTitleExtraInfo }>{intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.extra_info'})}</span>
        </h2>
        <BarChart
          categories={ categories }
          series={ series }
        />
      </div>
    );
  };

  const handleShowData = () => {
    const frequencyByAreaChartData = _.get(chartsData, 'frequencyByArea') || [];
    const frequencyByAreaChart = {
      title: !ageMode ? intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.area_frequency.title'}) : intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.area_per_age.title'}),
      columns: _.map(frequencyByAreaChartData, 'name'),
      rows: [
        {
          name: 'Total',
          data: _.map(frequencyByAreaChartData, 'value'),
        }
      ]
    };

    const frequencyByAreaAndClassChartData = _.get(chartsData, 'frequencyByAreaAndClass');
    const frequencyByAreaAndClassChartDataCategories = _.get(frequencyByAreaAndClassChartData, 'categories') || [];
    const frequencyByAreaAndClassChartDataSeries = _.get(frequencyByAreaAndClassChartData, 'series') || [];

    const frequencyByAreaAndClassChart = {
      title:
        !ageMode
        ? intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.area_frequency.subtitle'})
        : intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.area_per_age.subtitle'}),
      columns: frequencyByAreaAndClassChartDataCategories,
      rows: _.map(frequencyByAreaAndClassChartDataSeries, (serie) => {
        return {
          name: _.get(serie, 'name'),
          data: _.get(serie, 'data')
        };
      })
    };

    onShowData([
      frequencyByAreaChart,
      frequencyByAreaAndClassChart,
    ]);
  };

  const renderData = () => {
    if (emptyData) {
      return null;
    }

    return (
      <Fragment>
        { areaByPeriod() }
        { frequencyByArea() }
        { frequencyByAreaAndClass() }
        <div className={ styles.actionWrapper }>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={ handleShowData }
            className={ styles.actionButton }
          >
            <TableChartIcon />
            <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
          </Button>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      { loading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { emptyData &&
        <div className={ styles.infoBox }>
          <p>Dados em processo de atualização.</p>
        </div>
      }
      { renderData() }
    </Fragment>
  );
}
