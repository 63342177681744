import _ from 'lodash';

export default function parseQueryParamsToState(queryParams) {
  const {
    activeBaseMap,
    activeLayers,
    activeYear,
    layersOpacity,
    showBeforeAndAfterMosaic,
    timelineLimitsRange,
    baseParams,
  } = queryParams;
  let state = _.cloneDeep(queryParams);
  state = _.omit(state, 'baseParams');

  const parsedActiveBaseMap = Number(activeBaseMap);
  const parsedActiveYear = _.includes(activeYear, ',') ? _.map(_.split(activeYear, ','), Number) : Number(activeYear);
  const parsedLayersOpacity = Number(layersOpacity);

  if (activeBaseMap && !_.isNaN(parsedActiveBaseMap)) {
    _.set(state, 'activeBaseMap', parsedActiveBaseMap);
  }

  if (activeLayers) {
    const ids = _.map(_.split(activeLayers, ','));
    _.set(state, 'activeLayers', ids);
  }

  if (activeYear && (!_.isNaN(parsedActiveYear) || _.isArray(parsedActiveYear))) {
    _.set(state, 'activeYear', parsedActiveYear);
  }

  if (layersOpacity && !_.isNaN(parsedLayersOpacity)) {
    _.set(state, 'layersOpacity', parsedLayersOpacity);
  }

  if (showBeforeAndAfterMosaic) {
    _.set(state, 'showBeforeAndAfterMosaic', showBeforeAndAfterMosaic === 'true');
  }

  if (timelineLimitsRange) {
    const parsedTimelineLimitsRange = _.map(_.split(timelineLimitsRange, ','), Number);
    _.set(state, 'timelineLimitsRange', parsedTimelineLimitsRange);
  }

  if (baseParams) {
    const {
      groupType,
      territoryType,
      territorySubtype,
      territoryValueType,
      territory,
      territories,
      crossing,
      classesViewMode,
      activeClassTreeOptionValue,
      activeClassTreeNodeIds,
      activeObjectTreeNodeIds,
      activeSubmodule,
      activeClassesLevelsListItems,
      activeTransitionsClassesListItems,
      activePastureQualityQualityClassesListItems,
      yearRange,
      buffer,
    } = baseParams || {};

    if (groupType) {
      _.set(state, 'baseParams.groupType', groupType);
    }

    if (territoryType) {
      _.set(state, 'baseParams.territoryType', Number(territoryType));
    }

    if (territorySubtype) {}

    if (territoryValueType) {
      _.set(state, 'baseParams.territoryValueType', territoryValueType);
    }

    if (territory) {}

    if (territories) {
      const parsedTerritories = _.map(_.split(territories, ','), (territoryCode) => {
        const [
          id,
          label,
          territoryTypeId,
          territoryTypeLabel,
          bboxV1,
          bboxV2,
          bboxV3,
          bboxV4,
        ] = _.split(territoryCode, ';');
        const parsedBBox = [
          [Number(bboxV1), Number(bboxV2)],
          [Number(bboxV3), Number(bboxV4)],
        ];

        return {
          __typename: 'AppBaseParamsActiveTerritoryItemState',
          id: Number(id),
          label,
          territoryTypeId: Number(territoryTypeId),
          territoryTypeLabel,
          territoryBBox: parsedBBox,
        };
      });

      _.set(state, 'baseParams.territories', parsedTerritories);
    }

    if (crossing) {}

    if (classesViewMode) {
      _.set(state, 'baseParams.classesViewMode', classesViewMode);
    }

    if (activeClassTreeOptionValue) {
      _.set(state, 'baseParams.activeClassTreeOptionValue', activeClassTreeOptionValue);
    }

    if (activeClassTreeNodeIds) {
      const ids = _.map(_.split(activeClassTreeNodeIds, ','), Number);
      _.set(state, 'baseParams.activeClassTreeNodeIds', ids);
    }

    if (activeObjectTreeNodeIds) {
      const ids = _.map(_.split(activeObjectTreeNodeIds, ','), Number);
      _.set(state, 'baseParams.activeObjectTreeNodeIds', ids);
    }

    if (activeSubmodule) {
      _.set(state, 'baseParams.activeSubmodule', activeSubmodule);
    }

    if (activeClassesLevelsListItems) {
      const ids = _.map(_.split(activeClassesLevelsListItems, ','), Number);
      _.set(state, 'baseParams.activeClassesLevelsListItems', ids);
    }

    if (activeTransitionsClassesListItems) {
      const ids = _.map(_.split(activeTransitionsClassesListItems, ','));
      _.set(state, 'baseParams.activeTransitionsClassesListItems', ids);
    }

    if (activePastureQualityQualityClassesListItems) {
      const ids = _.map(_.split(activePastureQualityQualityClassesListItems, ','), Number);
      _.set(state, 'baseParams.activePastureQualityQualityClassesListItems', ids);
    }

    if (yearRange) {
      _.set(state, 'baseParams.yearRange', yearRange);
    }

    if (buffer) {
      _.set(state, 'baseParams.buffer', Number(buffer));
    }
  }

  return state;
}
