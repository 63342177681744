import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import LayersIcon from '@material-ui/icons/Layers';
import MapIcon from '@material-ui/icons/Map';
import OpacityIcon from '@material-ui/icons/Opacity';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';

import useFormatMessage from '../../../../../../hooks/useFormatMessage';

import styles from './MapControls.module.scss';

import baseLayersData from '../../../../../../data/baseLayers';
import baseMapsData from '../../../../../../data/baseMaps';

const useStyles = makeStyles((theme) => ({
  actionButtonActive: {
    backgroundColor: '#F7F7F7',
    '& svg': {
      fill: `${ theme.palette.primary.main } !important`
    },
  },
}));

const CustomTooltip = withStyles(() => ({
  tooltip: {
    padding: '8px 12px',
    fontSize: 12,
    fontWeight: 400,
    color: 'white',
    backgroundColor: '#444444',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12)',
  },
  arrow: {
    color: '#444444'
  },
}))(Tooltip);

const CustomSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: ({ customColor }) => customColor,
    },
    '&$checked + $track': {
      backgroundColor: ({ customColor }) => customColor,
    },
  },
  checked: {
    color: ({ customColor }) => customColor,
  },
  track: {
    color: ({ customColor }) => customColor,
  },
})(Switch);

export default function MapControls({
  activeBaseMap,
  showBeforeAndAfterMosaic,
  activeLayers,
  headerIsVisible,
  layersOpacity,
  onChange,
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [activeBox, setActiveBox] = useState(null);
  const [opacityValue, setOpacityValue] = useState(layersOpacity);
  const [activeLegendItem, setActiveLegendItem] = useState(null);
  const [activeBaseMapLegendItem, setActiveBaseMapLegendItem] = useState(null);

  useEffect(() => {
    if (layersOpacity) {
      setOpacityValue(layersOpacity);
    }
  }, [layersOpacity]);

  useEffect(() => {
    if (showBeforeAndAfterMosaic) {
      setActiveBox('opacity');
    }
  }, [showBeforeAndAfterMosaic]);

  const isLayerActive = (id) => {
    return !!_.find(activeLayers, (item) => id === item);
  };

  const handleLayersChange = (event) => {
    const id = event.target.name;
    let nextActiveItems = _.cloneDeep(activeLayers);

    if (isLayerActive(id)) {
      nextActiveItems = _.filter(
        nextActiveItems,
        item => item !== id
      );
    } else {
      nextActiveItems = _.concat(nextActiveItems, id);
    }

    onChange('activeLayers', nextActiveItems);
  };

  const handleBaseMapChange = (event) => {
    onChange('activeBaseMap', Number(event.target.value));
  };

  const getFormattedLabel = (baseKey, labelKey) => {
    return formatMessage(`map_controls.${ baseKey }.options.${ labelKey }`);
  };

  const handleToggleLegend = (id) => {
    if (activeLegendItem === id) {
      setActiveLegendItem(null);
    } else {
      setActiveLegendItem(id);
    }
  };

  const handleToggleBaseMapLegend = (id) => {
    if (activeBaseMapLegendItem === id) {
      setActiveBaseMapLegendItem(null);
    } else {
      setActiveBaseMapLegendItem(id);
    }
  };

  const handleOpacitySliderChangeCommitted = (_event, newValue) => {
    onChange('layersOpacity', newValue);
  };

  const handleOpacitySliderChange = (_event, newValue) => {
    setOpacityValue(newValue);
  };

  return (
    <div className={ classnames(styles.wrapper, {
      [styles.wrapperWithoutHeader]: !headerIsVisible,
      [styles.wrapperWithBeforeAndAfterMosaic]: showBeforeAndAfterMosaic,
    }) }>
      <div id="opacity-box" className={ styles.actionBox }>
        <CustomTooltip arrow title={ formatMessage('map_controls.opacity.title') } placement="right">
          <button
            className={ classnames(styles.actionButton, {
              [classes.actionButtonActive]: activeBox === 'opacity'
            }) }
            onClick={ setActiveBox.bind(this, 'opacity') }
          >
            <OpacityIcon />
          </button>
        </CustomTooltip>
        { activeBox === 'opacity' &&
          <ClickAwayListener onClickAway={ setActiveBox.bind(this, null) }>
            <div className={ classnames(styles.actionBoxContent, styles.actionBoxContentOpacity) }>
              <Slider
                orientation="vertical"
                valueLabelDisplay="auto"
                value={ opacityValue }
                onChange={ handleOpacitySliderChange }
                onChangeCommitted={ handleOpacitySliderChangeCommitted }
              />
            </div>
          </ClickAwayListener>
        }
      </div>
      <div id="layers-box" className={ styles.actionBox }>
        <CustomTooltip arrow title={ formatMessage('map_controls.layers.title') } placement="right">
          <button
            className={ classnames(styles.actionButton, styles.actionButtonOpacity, {
              [classes.actionButtonActive]: activeBox === 'layers'
            }) }
            onClick={ setActiveBox.bind(this, 'layers') }
          >
            <LayersIcon />
          </button>
        </CustomTooltip>
        { activeBox === 'layers' &&
          <ClickAwayListener onClickAway={ setActiveBox.bind(this, null) }>
            <div className={ styles.actionBoxContent }>
              <h3><FormattedMessage id="map_controls.layers.title" /></h3>
              <div className={ styles.actionBoxContentScrollWrapper }>
                { _.map(baseLayersData, (item) => {
                  return (
                    <div className={ styles.actionBoxItemWrapper }>
                      <div className={ styles.actionBoxItemHeader }>
                        <FormControlLabel
                          key={ `limit-control-${ item.key }` }
                          control={
                            <CustomSwitch
                              checked={ isLayerActive(item.key) }
                              onChange={ handleLayersChange }
                              name={ item.key }
                              customColor={ item.color }
                              size="small"
                            />
                          }
                          label={ getFormattedLabel('layers', item.labelKey) }
                          classes={ {
                            root: styles.switchControl,
                            label: styles.switchLabel
                          } }
                          className={ styles.actionBoxItemControl }
                        />
                        { item.downloadUrl &&
                          <IconButton
                            className={ styles.actionBoxControlButton }
                            href={ item.downloadUrl }
                            target="_blank"
                          >
                            <CloudDownloadIcon />
                          </IconButton>
                        }
                        { item.descriptionData &&
                          <IconButton
                            className={ classnames(styles.actionBoxControlButton, styles.actionBoxControlButtonToggle) }
                            onClick={ handleToggleLegend.bind(this, item.key) }
                          >
                            { activeLegendItem === item.key ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                          </IconButton>
                        }
                      </div>
                      <Collapse in={ activeLegendItem === item.key }>
                        <div className={ styles.actionBoxItemLegend }>
                          <ul>
                            { _.map(item.descriptionData, (content) => {
                              return (
                                <li key={ `legend-description-item-${ item.labelKey }-${ content.value }` }>
                                  <span><FormattedMessage id={ `map_controls.layers.description_data.labels.${ content.labelKey }` } />: </span>
                                  <span>{ content.value }</span>
                                </li>
                              );
                            }) }
                          </ul>
                        </div>
                      </Collapse>
                    </div>
                  );
                }) }
              </div>
            </div>
          </ClickAwayListener>
        }
      </div>
      <div id="basemap-box" className={ styles.actionBox }>
        <CustomTooltip arrow title={ formatMessage('map_controls.base_map.title') } placement="right">
          <button
            className={ classnames(styles.actionButton, styles.actionButtonBaseMap, {
              [classes.actionButtonActive]: activeBox === 'baseMap',
            }) }
            onClick={ setActiveBox.bind(this, 'baseMap') }
          >
            <MapIcon />
          </button>
        </CustomTooltip>
        { activeBox === 'baseMap' &&
          <ClickAwayListener onClickAway={ setActiveBox.bind(this, null) }>
            <div className={ `${styles.actionBoxContent} ${styles.baseMapsContentWrapper}` }>
              <h3><FormattedMessage id="map_controls.base_map.title" /></h3>
              <RadioGroup
                name="baseMap"
                value={ activeBaseMap }
                className={ styles.radioGroup }
              >
                { _.map(baseMapsData, (baseMap) => {
                  const isDisabled = showBeforeAndAfterMosaic && (
                    baseMap.labelKey !== 'mosaic' &&
                    baseMap.labelKey !== 'coverage'
                  );

                  return (
                    <>
                      <div className={styles.baseMapsItemWrapper}>
                        <FormControlLabel
                          key={ `baseMap-control-${ baseMap.id }` }
                          disabled={ isDisabled }
                          value={ baseMap.id }
                          label={ getFormattedLabel('base_map', baseMap.labelKey) }
                          control={ <Radio className={ styles.radioGroupInput } color="primary" size="small" /> }
                          classes={ {
                            root: styles.radioGroupControl,
                            label: styles.radioGroupLabel
                          } }
                          onChange={ handleBaseMapChange }
                        />
                        { baseMap.descriptionData &&
                          <IconButton
                            className={ classnames(styles.actionBoxControlButton, styles.actionBoxControlButtonToggle) }
                            onClick={ handleToggleBaseMapLegend.bind(this, baseMap.id) }
                          >
                            { activeBaseMapLegendItem === baseMap.id ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                          </IconButton>
                        }
                      </div>
                      <Collapse in={ activeBaseMapLegendItem === baseMap.id }>
                        <div className={ styles.actionBoxItemLegend }>
                          <ul>
                            { _.map(baseMap.descriptionData, (content) => {
                              return (
                                <li key={ `legend-description-item-${ baseMap.labelKey }-${ content.value }` }>
                                  <span><FormattedMessage id={ `map_controls.layers.description_data.labels.${ content.labelKey }` } />: </span>
                                  <span>{ content.value }</span>
                                </li>
                              );
                            }) }
                          </ul>
                        </div>
                      </Collapse>
                    </>
                  );
                }) }
              </RadioGroup>
            </div>
          </ClickAwayListener>
        }
      </div>
    </div>
  );
}
