import React, { useEffect } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { useApolloClient, useQuery } from '@apollo/react-hooks';

import ClassesControl from './components/ClassesControl';
import ClassLegendList from './components/ClassLegendList';
import NaturalOrAnthropicLegendList from './components/NaturalOrAnthropicLegendList';

import usePrevious from '../../../../../../hooks/usePrevious';

import {
  GET_BASE_DATA,
  GET_CLASS_TREE_DATA,
} from './query';

import styles from './Classes.module.scss';

export default function Classes({
  activeClassesParamsKey = 'activeClassesLevelsListItems',
  className,
  disabled = false,
  classTreeKey,
  defaultOrphanActive = false,
  defaultInactive = false,
  levels = [],
  hideLevelsToggle,
  showDescription,
  viewModeOptions,
  onShowInfo,
  onlyShowControlOnLeafs,
  titleKey,
  hideIndex,
}) {
  const locale = localStorage.getItem('locale') || 'es';
  const client = useApolloClient();
  const { data: baseData, loading: loadingBaseData } = useQuery(GET_BASE_DATA);

  const { data: defaultClassTreeData, loading: loadingDefaultClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: 'default'
    }
  });
  const { data: naturalOrAnthropicClassTreeData, loading: loadingNaturalOrAnthropicClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: 'natural_or_anthropic'
    }
  });

  const activeClassesLevelsListItems = _.get(baseData, `app.baseParams.${ activeClassesParamsKey }`);
  const classesViewMode = _.get(baseData, 'app.baseParams.classesViewMode');
  let classesLevelsList;

  if (classTreeKey === 'default') {
    classesLevelsList = _.get(defaultClassTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
  } else if (classTreeKey === 'natural_or_anthropic') {
    classesLevelsList = _.get(naturalOrAnthropicClassTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
  }

  const prevClassesLevelsList = usePrevious(classesLevelsList);

  const isOnInitialRenderingWithQueryParams = !_.isEmpty(activeClassesLevelsListItems) &&
    _.isUndefined(prevClassesLevelsList);

  let parsedClassesLevelsList = _.map(classesLevelsList, (item) => {
    // TODO: Load tooltipsList with new query
    const tooltipsList = null;
    const stringList = _.get(item, 'i18nStrings');
    const selectedString = _.find(stringList, { language: locale });

    return {
      id: item.id,
      level: item.level,
      parentId: item.parentId,
      label: _.get(selectedString, 'stringValue'),
      color: item.color,
      position: _.last(item.positionInTree),
      tooltipsList
    };
  });

  const activeClassificationLevels = _.filter(levels, (levelId) => {
    const idsByLevel = _.map(_.filter(parsedClassesLevelsList, { level: levelId }), 'id');
    const idsByLevelActive = _(idsByLevel)
      .filter((id) => _.includes(activeClassesLevelsListItems, id))
      .compact()
      .value();

    return _.size(idsByLevelActive) === _.size(idsByLevel);
  });

  const updateClientAppBaseParamsState = (params) => {
    client.writeData({
      data: {
        app: {
          __typename: 'AppState',
          baseParams: {
            __typename: 'AppBaseParamsState',
            ...params
          }
        }
      }
    });
  };

  const updateActiveClassesLevelsListItems = (ids) => {
    updateClientAppBaseParamsState({ [activeClassesParamsKey]: ids });
  };

  useEffect(() => {
    if (isOnInitialRenderingWithQueryParams) {
      return;
    }

    if (classesLevelsList && !defaultInactive) {
      let ids = _.map(classesLevelsList, 'id');

      if (defaultOrphanActive) {
        ids = _.map(_.filter(classesLevelsList, ({ parentId }) => !!parentId), 'id');
      } else if (onlyShowControlOnLeafs) {
        const filteredItems = _.filter(classesLevelsList, (item) => {
          const hasChildItems = !!_.find(classesLevelsList, { level: item.level + 1, parentId: item.id });

          return !hasChildItems;
        });
        ids = _.map(filteredItems, 'id');
      }

      updateActiveClassesLevelsListItems(ids);
    } else {
      updateActiveClassesLevelsListItems([]);
    }
  }, [classesLevelsList, classTreeKey]);

  const handleClassificationLevelToggle = (levelId) => {
    const idsByLevel = _.map(_.filter(parsedClassesLevelsList, { level: levelId }), 'id');

    if (_.includes(activeClassificationLevels, levelId)) {
      let idsClone = _.clone(activeClassesLevelsListItems);
      idsClone = _.filter(idsClone, (id) => !_.includes(idsByLevel, id));

      updateActiveClassesLevelsListItems(idsClone);
    } else {
      const nextIds = _.uniq(_.concat(activeClassesLevelsListItems, idsByLevel));

      updateActiveClassesLevelsListItems(nextIds);
    }
  };

  const handleClassificationLevelToggleByParentId = (parentId, active) => {
    const idsByParentId = _.map(_.filter(parsedClassesLevelsList, { parentId }), 'id');

    if (active) {
      const nextIds = _.uniq(_.concat(activeClassesLevelsListItems, idsByParentId));

      updateActiveClassesLevelsListItems(nextIds);
    } else {
      let idsClone = _.clone(activeClassesLevelsListItems);
      idsClone = _.filter(idsClone, (id) => !_.includes(idsByParentId, id));

      updateActiveClassesLevelsListItems(idsClone);
    }
  };

  const handleClassesLevelsListChange = (ids) => {
    updateActiveClassesLevelsListItems(ids);
  };

  const handleViewModeChange = (mode) => {
    updateClientAppBaseParamsState({ classesViewMode: mode });
  };

  return (
    <div className={ classnames(styles.wrapper, className) }>
      <ClassesControl
        activeClassificationLevels={ activeClassificationLevels }
        disabled={ disabled }
        viewMode={ classesViewMode }
        levels={ levels }
        hideLevelsToggle={ hideLevelsToggle }
        showDescription={ showDescription }
        viewModeOptions={ viewModeOptions }
        onClassificationLevelToggle={ handleClassificationLevelToggle }
        onViewModeChange={ handleViewModeChange }
        titleKey={ titleKey }
      />
      { classesViewMode !== 'naturalOrAnthropic' &&
        <ClassLegendList
          disabled={ disabled }
          activeClassesLevelsListItems={ activeClassesLevelsListItems }
          parsedClassesLevelsList={ parsedClassesLevelsList }
          onItemsChange={ handleClassesLevelsListChange }
          onShowInfo={ onShowInfo }
          onlyShowControlOnLeafs={ onlyShowControlOnLeafs }
          hideIndex={ hideIndex }
        />
      }
      { classesViewMode === 'naturalOrAnthropic' &&
        <NaturalOrAnthropicLegendList
          activeClassesLevelsListItems={ activeClassesLevelsListItems }
          parsedClassesLevelsList={ parsedClassesLevelsList }
          viewMode={ classesViewMode }
          onItemsChange={ handleClassesLevelsListChange }
          onClassificationLevelToggleByParentId={ handleClassificationLevelToggleByParentId }
          onShowInfo={ onShowInfo }
        />
      }
    </div>
  );
}
