import { gql } from 'graphql.macro';

export const GET_BASE_DATA = gql`
  {
    territoryCategoriesList {
      id
      key
      parentId
      i18nStrings {
        language
        stringValue
      }
    }

    app @client {
      activeYear
      activeModule
      activeModuleContent
      baseParams {
        groupType
        territory
        territories {
          id
          label
          territoryTypeId
          territoryTypeLabel
          territoryBBox
        }
        classesViewMode
        activeClassTreeNodeIds
        activeClassTreeOptionValue
        activeObjectTreeNodeIds
        activeSubmodule
        activeClassesLevelsListItems
        activeTransitionsClassesListItems
        activePastureQualityQualityClassesListItems
        yearRange
        buffer
      }
      headerIsVisible
      mapFlyTo
      mapPointInfo {
        latlng
        parsedBBox
      }
      mapPosition
      mapboxMode
      showClassInfo
      showDashboardInfo
      timelineLimitsRange
      showBeforeAndAfterMosaic
    }
  }
`;

export const GET_TERRITORIES_LIST = gql`
  query TerritoriesList($categoryId: Int!) {
    territoriesList(categoryId: $categoryId) {
      id
      i18nStrings {
        language
        stringValue
      }
    }
  }
`;

export const GET_CLASS_TREE_DATA = gql`
  query GetClassTreeData(
    $classTreeKey: String!,
  ) {
    classTreeByKey(key: $classTreeKey) {
      id
      key
     	i18nStrings {
				language
				stringValue
      }
			mvClassTreeLevelsUi {
        id
        color
        classId
        childrenIds
        positionInTree
        level
        parentId
        defaultName
        classTreeId
				i18nStrings {
					language
					stringValue
				}
      }
    }
  }
`;
