import React from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';

import useFormatMessage from '../../../../../../hooks/useFormatMessage';

import styles from './TranslateOptionsBox.module.scss';

export default function TranslateOptionsBox({
  anchorEl,
  onClose = () => {},
}) {
  const formatMessage = useFormatMessage()
  const open = Boolean(anchorEl);

  const handleLocaleChange = (locale) => {
    localStorage.setItem('locale', locale);
    window.location.href = '/';
  };

  return (
    <Popper open={ open } anchorEl={ anchorEl } placement="right-end" transition style={{ zIndex: 9999 }}>
      { ({ TransitionProps }) => (
        <Fade { ...TransitionProps } timeout={ 350 }>
          <ClickAwayListener onClickAway={ onClose }>
            <Paper className={ styles.boxWrapper }>
              <button className={ styles.button } onClick={ handleLocaleChange.bind(this, 'es') }>ES - { formatMessage('mapbiomas.header.locale_control.options.es') }</button>
              <button className={ styles.button } onClick={ handleLocaleChange.bind(this, 'en') }>EN - { formatMessage('mapbiomas.header.locale_control.options.en') }</button>
            </Paper>
          </ClickAwayListener>
        </Fade>
      ) }
    </Popper>
  );
}
