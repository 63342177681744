import { gql } from 'graphql.macro';

export const GET_CLASS_TREE_DATA = gql`
  query GetClassTreeData(
    $classTreeKey: String!,
  ) {
    classTreeByKey(key: $classTreeKey) {
      id
      key
     	i18nStrings {
				language
				stringValue
      }
			mvClassTreeLevelsUi {
        id
        color
        classId
        childrenIds
        positionInTree
        level
        parentId
        defaultName
        classTreeId
				i18nStrings {
					language
					stringValue
				}
      }
    }
  }
`;

export const GET_BASE_DATA = gql`
  query GetBaseData(
    $classTreeNodeIds: [Int!]!,
    $fromYear: Int!,
    $toYear: Int!,
    $territoriesIds: [Int!]!,
    $locale: String
  ) {
    frequencyAggregated(
      classTreeNodeIds: $classTreeNodeIds,
      fromYear: $fromYear,
      toYear: $toYear,
      territoriesIds: $territoriesIds,
    ) {
      average
      maximum
      minimum
      total
    }

    frequencySeriesAggregated(
      classTreeNodeIds: $classTreeNodeIds,
      fromYear: $fromYear,
      toYear: $toYear,
      territoriesIds: $territoriesIds,
    ) {
      label
      value
    }

    frequencySeriesAggregatedByClass(
      classTreeNodeIds: $classTreeNodeIds,
      fromYear: $fromYear,
      toYear: $toYear,
      territoriesIds: $territoriesIds,
      locale: $locale
    )

    # getAreaFrequenceByClass(
    #   classTreeNodeIds: $classTreeNodeIds,
    #   fromYear: $fromYear,
    #   toYear: $toYear,
    #   territoriesIds: $territoriesIds,
    # ){
    #   territoriesIds
    #   fromYear
    #   toYear
    #   classTreeNodeIds
    #   freqByClass {
    #     areaInHa
    #     classRegisterId
    #     classNameEn
    #     classNamePt
    #     color
    #   }
    # }
  }
`;
